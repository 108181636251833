import React, { useEffect } from 'react';
import { useState } from 'react';
import {connect} from 'react-redux';
import { withRoles } from "../../../../router/SecuredRoute";
import {useSubheader} from "../../../../../_metronic/layout";
import { LoadingSpinner } from '../../../../components';
import { actions } from '../../actions';
import { actions as commonActions } from '../../../commonReduxActions/actions';
import {makeStyles} from "@material-ui/styles";
import FilePresentIcon from '@mui/icons-material/FilePresent';
import {checkAuthorization} from "../../../auth/authorization";
import {Button} from "react-bootstrap";
import '../customStylesForContractManagement.css'
import Stack from "@mui/material/Stack";
import {useForm} from "react-hook-form";
import {getServiceName} from "../../../../utils/roleRelatedValues/serviceNames";
import {getFeatureName} from "../../../../utils/roleRelatedValues/featureNames";
import {getActionName} from "../../../../utils/roleRelatedValues/actionNames";
import ContentWrapper from "../../../../components/contentWrapper/contentWrapper";
import ContractSummary from "./sections/contractSummary"
import AdditionalInfoWithFeesAndCharges from "./sections/additionalInfoWithFees"
import RepaymentSchedule from "./sections/repaymentSchedule"
import OperationalLog from "./sections/operationalLog"
import NomineeInfo from "./sections/nomineeInfo"
import Settings from "./sections/settings"

const useStylesIconButton = makeStyles({
    root: {
        marginTop: '0',
        paddingTop: '4px',
    },
});

const ContractDetails = ({ data, ...props }) => {
    const {register, errors, control, clearErrors, handleSubmit, reset, setValue, setError} = useForm()
    const [language, setLanguage] = useState("EN");
    const [fullLoanDetails, setFullLoanDetails] = useState(undefined);
    const [loanID, setLoanID] = useState('');
    const [loanType, setLoanType] = useState('');
    const [leaseType, setLeaseType] = useState('');
    const [perRentPayment, setPerRentPayment] = useState('');
    const [perInstallmentPayment, setPerInstallmentPayment] = useState('');
    const [loanDocName, setLoanDocName] = useState('')
    const [loanDocLink, setLoanDocLink] = useState('')
    const [garageName, setGarageName] = useState('')
    const [garageGuid, setGarageGuid] = useState('')
    const [borrowerName, setBorrowerName] = useState('')

    const [keyCountForSettingsComponent, setKeyCountForSettingsComponent] = useState(0)
    const [canTerminate, setCanTerminate] = useState(false)
    const [canEdit, setCanEdit] = useState(false)
    const [canReschedule, setCanReschedule] = useState(false)

    const [canPause, setCanPause] = useState(false)
    const [canResume, setCanResume] = useState(false)

    const [canUpdateInstallment, setUpdateInstallment] = useState(false)

    const garageServiceName = getServiceName('garageService')
    const transactionServiceName = getServiceName('transactionService')
    const contractManagementFeatureName = getFeatureName('contractManagementFeature')
    const invoiceFeatureName = getFeatureName('invoiceFeature')
    const deleteActionName = getActionName('deleteAction')
    const updateActionName = getActionName('updateAction')
    const installmentUpdateActionName = getActionName('contractInstallmentUpdateAction')
    const rescheduleInvoiceActionName = getActionName('contractRescheduleInvoiceAction')
    const pauseActionName = getActionName('contractPauseAction')
    const resumeActionName = getActionName('contractResumeAction')

    const classes = useStylesIconButton();
    const subheader = useSubheader();

    const {
        COLLECT_LOAN_DETAILS,
        GET_GARAGE_LIST,
        RETURN_TO_INITIAL_STATE
    } = props;

    useEffect(() => {
        setLanguage(props.language)

        setLoanID(props.match.params.contract_id)
        COLLECT_LOAN_DETAILS(props.match.params.contract_id)
        GET_GARAGE_LIST()

        setCanTerminate(checkAuthorization(props.authorization, garageServiceName, contractManagementFeatureName, [deleteActionName]))
        setCanEdit(checkAuthorization(props.authorization, garageServiceName, contractManagementFeatureName, [updateActionName]))
        setUpdateInstallment(checkAuthorization(props.authorization, garageServiceName, contractManagementFeatureName, [installmentUpdateActionName]))
        setCanReschedule(checkAuthorization(props.authorization, transactionServiceName, invoiceFeatureName, [rescheduleInvoiceActionName]))
        setCanPause(checkAuthorization(props.authorization, garageServiceName, contractManagementFeatureName, [pauseActionName]))
        setCanResume(checkAuthorization(props.authorization, garageServiceName, contractManagementFeatureName, [resumeActionName]))

        subheader.setBreadCrumbComponent(null)
        subheader.setActionButtons(null)

        return ()=>{
            RETURN_TO_INITIAL_STATE()
        }
    }, [])

    useEffect(() => {
        if (props.loanDetails) {
            const loanDetails = props.loanDetails
            setFullLoanDetails(loanDetails)
            let loanID = loanDetails.contract_id
            let loanType = loanDetails.contract_type
            let garageName = loanDetails.device_details?.garage_name
            let leaseType = loanDetails.lease_payment_interval
            let perRentPayment = loanDetails.per_rent_payable || loanDetails.per_rent_payable === 0 ?
                new Intl.NumberFormat('en-IN').format(loanDetails.per_rent_payable.toFixed(2)) : ''
            let perInstallmentPayment = loanDetails.per_installment_payable || loanDetails.per_installment_payable === 0 ?
                new Intl.NumberFormat('en-IN').format(loanDetails.per_installment_payable.toFixed(2)) : ''
            setLoanType(loanType)
            setLeaseType(leaseType)
            setGarageName(garageName)
            setGarageGuid(loanDetails.device_details?.garage_guid)
            setPerRentPayment(perRentPayment)
            setPerInstallmentPayment(perInstallmentPayment)

            let breadCrumbText = garageName ? 'Contract #' + loanID + ' - ' + garageName : 'Contract #' + loanID
            if (loanType === 'rent') {
                breadCrumbText += ' - ' + perRentPayment + ' - Rent'
            } else {
                breadCrumbText += ' - ' + perInstallmentPayment + ' - ' + leaseType.charAt(0).toUpperCase() + leaseType.slice(1)
            }
            subheader.setBreadCrumbComponent(
                <>
                    <h5 className="text-dark font-weight-bold my-2 mr-5"><a onClick={() =>
                    {props.history.push({pathname: "/contract-management/list"})}}>Contracts</a> / {breadCrumbText}</h5>
                </>
            )

            setBorrowerName(loanDetails.borrower_name)

            setLoanDocName(loanDetails.doc_file_name? loanDetails.doc_file_name:'')
            setLoanDocLink(loanDetails.doc_file_link? loanDetails.doc_file_link:'')

            subheader.setActionButtons(<>
                <div className="d-flex align-item-center justify-content-end">
                    <Stack
                        direction="column"
                        justifyContent="flex-end"
                        alignItems="flex-end"
                        spacing={0.5}
                        className={"mt-2"}
                    >
                        {(canTerminate || canEdit || canReschedule || canUpdateInstallment || canPause || canResume) && ['active', 'paused'].includes(loanDetails.status)? <>
                            <Settings key={keyCountForSettingsComponent}
                                      contractDetails={props.loanDetails}
                                      userCanEdit={canEdit}
                                      userCanTerminate={canTerminate}
                                      userCanReschedule={canReschedule}
                                      userCanUpdateInstallment={canUpdateInstallment}
                                      userCanPause={canPause} userCanResume={canResume}/>
                        </>:<>
                            {loanDetails.status === 'terminated'? <>
                                <Button size={'sm'} type="button" style={{
                                    backgroundColor: '#cb152b',
                                }} classes={classes} className={'ml-1'}>
                                    Terminated
                                </Button>
                            </>:<>
                                {loanDetails.status === 'paused'? <>
                                    <Button size={'sm'} type="button" style={{
                                        backgroundColor: '#F18D00',
                                    }} classes={classes} className={'ml-1'}>
                                        Paused
                                    </Button>
                                </>:<>
                                    <Button size={'sm'} type="button" style={{
                                        backgroundColor: '#0b6e4f',
                                    }} classes={classes} className={'ml-1'}>
                                        Active
                                    </Button>
                                </>}
                            </>}
                        </>}
                        {['active', 'paused'].includes(loanDetails.status)? <>
                            <div className="small mb-2">Created By {loanDetails.created_by_name}, {loanDetails.organization_details.name}</div>
                        </>:<></>}
                    </Stack>
                </div>
            </>)
            setKeyCountForSettingsComponent(keyCountForSettingsComponent + 1)
        } else {
            setFullLoanDetails(undefined)
        }
    }, [props.loanDetails])

    // Saving latest garage information
    useEffect(() => {
        if (props.garages && props.garages.length > -1) {
            const garages = props.garages;
            const filteredData = garages.filter(garage => garage.pk === garageGuid)
            if (filteredData.length > 0) {
                if (filteredData[0].name) {
                    setGarageName(filteredData[0].name)
                }
                if (filteredData[0].garage_owner) {
                    setBorrowerName(filteredData[0].garage_owner)
                }
                if (fullLoanDetails && filteredData[0].name) {
                    // Re-populating the breadcrumb text
                    let breadCrumbText = 'Contract #' + loanID + ' - ' + filteredData[0].name
                    if (loanType === 'rent') {
                        breadCrumbText += ' - ' + perRentPayment + ' - Rent'
                    } else {
                        breadCrumbText += ' - ' + perInstallmentPayment + ' - ' + leaseType.charAt(0).toUpperCase() + leaseType.slice(1)
                    }
                    subheader.setBreadCrumbComponent(
                        <>
                            <h5 className="text-dark font-weight-bold my-2 mr-5"><a onClick={() => {props.history.push({pathname: "/contract-management/list"})}}>Contracts</a> / {breadCrumbText}</h5>
                        </>
                    )
                }
            }
        }
    }, [props.garages, fullLoanDetails])

    // Table header styling
    const tableHeaderLeftAlignedColumnStyling = {
        fontWeight: 500,
        fontSize: "1.1rem",
        color: "#8C8C9B",
        // paddingLeft: "0px",
        textAlign: "left",
        // width: 'calc(6.16667px)',
        boxSizing: "borderBox"
    }

    const tableHeaderRightAlignedColumnStyling = {
        fontWeight: 500,
        fontSize: "1.1rem",
        color: "#8C8C9B",
        paddingLRight: "0px",
        textAlign: "right",
        boxSizing: "borderBox"
    }

    // Table cell related styling
    const tableLeftAlignedContentStyling = {
        color: "inherit",
        // width: calc(112.5px),
        boxSizing: "border-box",
        fontSize: "inherit",
        fontFamily: "inherit",
        fontWeight: "inherit",
        // paddingLeft: "0px",
        // width: 'calc(47.333px)',
        textAlign: "left",
        // whiteSpace: 'nowrap'
    }

    const tableRightAlignedContentStyling = {
        color: "inherit",
        // width: calc(112.5px),
        boxSizing: "border-box",
        fontSize: "inherit",
        fontFamily: "inherit",
        fontWeight: "inherit",
        // paddingRight: "0px",
        // width: 'calc(47.333px)',
        textAlign: "right",
        // whiteSpace: 'nowrap'
    }

    return (
        <ContentWrapper showCardHeader={false} showBackButton={false} isLoading={props.isLoading} serverError={false}
                        permissionDenied={false} pageTitle={'Loan Details'} statusCode={props.statusCode}>
            {!props.loanDetailsCollectionInProgress && fullLoanDetails? <>
                {Object.keys(fullLoanDetails).length > 0? <>
                    {/* Top summary table */}
                    <ContractSummary contractDetails={fullLoanDetails} garageName={garageName}
                                     borrowerName={borrowerName} tableHeaderLeftAlignedColumnStyling={tableHeaderLeftAlignedColumnStyling}
                                     tableHeaderRightAlignedColumnStyling={tableHeaderRightAlignedColumnStyling}
                                     tableLeftAlignedContentStyling={tableLeftAlignedContentStyling}
                                     tableRightAlignedContentStyling={tableRightAlignedContentStyling}/>

                    {/* "Fees and Charges" and "Additional info" tables */}
                    <AdditionalInfoWithFeesAndCharges contractDetails={fullLoanDetails}
                                                      tableHeaderLeftAlignedColumnStyling={tableHeaderLeftAlignedColumnStyling}
                                                      tableLeftAlignedContentStyling={tableLeftAlignedContentStyling}/>

                    {/* "Nominee" table */}
                    <NomineeInfo contractDetails={fullLoanDetails}
                                 tableHeaderLeftAlignedColumnStyling={tableHeaderLeftAlignedColumnStyling}
                                 tableLeftAlignedContentStyling={tableLeftAlignedContentStyling}/>

                    {/* Show "Repayment Schedule" section only when loan is lease based */}
                    {loanType === 'rent'? null:<>
                        <RepaymentSchedule contractDetails={fullLoanDetails}/>
                    </>}

                    {/* "Operational Logs" table */}
                    <OperationalLog contractDetails={fullLoanDetails}/>

                    {/* Uploaded document during contract creation */}
                    {loanDocName? <>
                        <div className={'row mt-10'}>
                            <div className={'col-md-1'}></div>
                            <div className={'col-md-10'}>
                                <h6><strong>Documents</strong></h6>
                            </div>
                            <div className={'col-md-1'}></div>
                        </div>
                        <div className={'row mt-5'}>
                            <div className={'col-md-1'}></div>
                            <div className={'col-md-11'} style={{display: "flex", flexDirection: "row"}}>
                                {loanDocLink? <>
                                    <a href={loanDocLink} download={loanDocName}><FilePresentIcon/> <strong>{loanDocName}</strong></a>
                                </>:<>
                                    <FilePresentIcon/> <h6><strong>{loanDocName}</strong></h6>
                                </>}
                            </div>
                        </div>
                    </>:null}
                </>:<>
                    <div className={'row'}>
                        <div className={'col-md-12'}>
                            <p className={'text-center'}><h1>No details available</h1></p>
                        </div>
                    </div>
                </>}
            </>:<LoadingSpinner
                loadingSubText={'Collecting details of the contract ' + loanID + ' ..'} language={language}/>}
        </ContentWrapper>
    )
}

ContractDetails.propTypes = {}

const mapStateToProps = (state) => {
    return {
        language: state.auth.language,
        authorization: state.auth.authorizations,
        loanDetails: state.contractManagementReducer.loanDetails,
        loanDetailsCollectionInProgress: state.contractManagementReducer.loanDetailsCollectionInProgress,
        garages: state.commonReducer.garages
    }
}

export default connect(mapStateToProps, { ...actions, ...commonActions })(withRoles(ContractDetails));