import React, {Fragment, useEffect, useState} from 'react';
// import PropTypes from 'prop-types';
import ContentWrapper from "../../../../components/contentWrapper/contentWrapper";
import DataTable from "../../../../components/dataTable/DataTable";
import styled from "styled-components";
import {Filter, Toolbar} from "../components";
import FormContainer from "../components";
import {connect} from "react-redux";
import {actions} from "../../actions";
import {Button, Form, Spinner} from "react-bootstrap";
import IconButton from "@material-ui/core/IconButton";
import FilterListIcon from "@material-ui/icons/FilterList";
import {CloudDownloadOutlined} from "@material-ui/icons";
import {SolInput} from "../../../../components/SolStyledComponents/components";
import {makeStyles} from "@material-ui/styles";
import {DataTableContainer, getLoadingSpinner, requestCycle} from '../../utils';
import {LoadingSpinner} from '../../../../components/LoadingSpinnerForDataTable';
import {Link} from "react-router-dom";
import {useSubheader} from "../../../../../_metronic/layout";
import {isNumber, JsonToUriSerialize, parseParams} from "../../../../utils/utilityFunctions";
import {withRoles} from "../../../../router/SecuredRoute";
import {GenericModal} from "../../../../components/genericModal/genericModal";
import moment from "moment";
import {getServiceName} from "../../../../utils/roleRelatedValues/serviceNames";
import {getFeatureName} from "../../../../utils/roleRelatedValues/featureNames";
import {getActionName} from "../../../../utils/roleRelatedValues/actionNames";
import {SolTypography} from "../../../../components/utilityComponents/SOlTypography";
import {checkAuthorization} from "../../../auth/authorization";
import {toast} from "react-toastify";
import {useForm} from "react-hook-form";
import InfoIcon from "@mui/icons-material/Info";
import EditIcon from "@mui/icons-material/Edit";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import {showNotifications} from "../../../../utils/notification";
import Typography from "@mui/material/Typography";
import Skeleton from '@mui/material/Skeleton';


const useStylesIconButton = makeStyles({
    root: {
        marginTop: '0',
        paddingTop: '4px',
    },
});

const RoleList = (props) => {
    const {register, errors, control, clearErrors, handleSubmit, reset, setValue, setError} = useForm()
    const [showFilter, setShowFilter] = useState(false);
    const [roleList, setRoleList] = useState(undefined);
    const [page, setPage] = useState(1);
    const [queryParams, setQueryParams] = useState(null)
    const [initialfilterParam, setInitialfilterParam] = useState(null)
    const [filterParamForApiCall, setFilterParamForApiCall] = useState(null)
    const [urlIsEdited, setUrlIsEdited] = useState(false)
    const subHeader = useSubheader();
    const [organizations, setOrganizations] = useState(null);
    const [roleName, setRoleName] = useState(null);
    const [orgName, setOrgName] = useState(null);
    const [permittedActions, setPermittedActions] = useState([]);
    const [showDetailsModal, setShowDetailsModal] = useState(false);

    // Duplication related
    const [showRoleDuplicationModal, setShowRoleDuplicationModal] = useState(false);
    const [roleDuplicationModalTitle, setRoleDuplicationModalTitle] = useState('');
    const [guidOfRole, setGuidOfRole] = useState('');
    const [reloadingRoleList, setReloadingRoleList] = useState(false);

    // Permission related
    const [authorization, setAuthorization] = useState(null);
    const [language, setLanguage] = useState('EN');
    const userServiceName = getServiceName('userService');
    const roleFeatureName = getFeatureName('roleFeature');
    const createActionName = getActionName('createAction');
    const updateActionName = getActionName('updateAction');
    const duplicateActionName = getActionName('duplicateAction');
    const [actions, setActions] = useState([]) // Permitted actions

    const roleListColumns = [
        {
            field: 'name',
            title: language === 'EN'? 'Name':'নাম',
            emptyValue: ()=>{
                return "N/A"
            },
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            }
            // disableClick: true,
        },
        {
            field: 'created_at',
            title: language === 'EN'? 'Created At':'তৈরির সময়',
            emptyValue: ()=>{
                return "N/A"
            },
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            disableClick: true
        }
    ]

    const {
        GET_ROLE_LIST,
        GET_ORGANISATIONS,
        DUPLICATE_ROLE,
        RETURN_TO_INITIAL_STATE_OF_ROLE_FEATURE
    } = props;
    const classes = useStylesIconButton();

    useEffect(() => {
        setLanguage(props.language)
        setAuthorization(props.authorization)
        if (checkAuthorization(props.authorization, userServiceName, roleFeatureName, [createActionName])) {
            subHeader.setActionButtons(
                <div className="d-flex align-item-center justify-content-end">
                    <Button variant="warning" type="button" size={'sm'} style={{
                        backgroundColor: '#F18D00 !important',
                    }} onClick={gotToCreateRole} classes={classes} >
                        {props.language === 'EN'? <i className="fa fa-plus" aria-hidden="true"></i>:null}
                        {props.language === 'EN'? 'Create Role':'ব্যবহারকারীর ধরন তৈরি করুন'}
                    </Button>
                </div>
            )
        } else {
            subHeader.setActionButtons(null)
        }
        subHeader.setBreadCrumbComponent(null)
        if (!props.history.location.search) {
            setPage(1)
            props.history.push({
                search: 'page=1'
            })
        }
        GET_ORGANISATIONS()

        const roleUpdatePermitted = checkAuthorization(props.authorization, userServiceName,
            roleFeatureName, [updateActionName])
        const roleDuplicationPermitted = checkAuthorization(props.authorization, userServiceName,
            roleFeatureName, [duplicateActionName])
        const detailsActionButton = {
            position: "row",
            action: (rowData) => ({
                icon: () => {
                    return <Button variant={'warning'}
                                   size={'sm'}
                                   style={{ whiteSpace: 'nowrap' }} className={'ml-1'}>
                        <InfoIcon fontSize={"small"}/> Details
                    </Button>
                },
                onClick: (e, row) => showRoleDetails(row)
            })
        }
        const updateActionButton = {
            position: "row",
            action: (rowData) => ({
                icon: () => {
                    return <Button variant={'warning'}
                                   size={'sm'}
                                   style={{ whiteSpace: 'nowrap' }} className={'ml-1'}>
                        <EditIcon fontSize={"small"}/> Update
                    </Button>
                },
                onClick: (e, row) => goToRoleUpdatePage(row)
            })
        }
        const duplicateActionButton = {
            position: "row",
            action: (rowData) => ({
                icon: () => {
                    return <Button variant={'warning'}
                                   size={'sm'}
                                   style={{ whiteSpace: 'nowrap' }} className={'ml-1'}>
                        <ContentCopyIcon fontSize={"small"}/> Duplicate
                    </Button>
                },
                onClick: (e, row) => initiateRoleDuplicateProcess(row)
            })
        }

        let actionButtons = [detailsActionButton]
        if (roleUpdatePermitted && roleDuplicationPermitted) {
            actionButtons.push(updateActionButton, duplicateActionButton)
        } else if (roleUpdatePermitted && !roleDuplicationPermitted) {
            actionButtons.push(updateActionButton)
        } else if (!roleUpdatePermitted && roleDuplicationPermitted) {
            actionButtons.push(duplicateActionButton)
        }
        setActions(actionButtons)

        return ()=> {
            RETURN_TO_INITIAL_STATE_OF_ROLE_FEATURE()
        }
    }, []);


    // ----- Begin: Section related to shareable link/handling query params ---- //
    const validationKeyArray = ['page']

    useEffect(() => {
        let temp = null;
        try {
            temp = parseParams(props.history?.location?.search);
            if (Object.keys(temp)?.length > 0) {
                /* Set the projected filter lists. Requires validation */
                setQueryParams(temp);
            }
        } catch (e) {
            console.debug(e);
        }
    },[props.history.location.search])

    useEffect(() => {
        let keys = null;
        const validParameters = {};
        /* Only these keys in the projected params are valid in the projected params */

        /* Getting the keys in the projected params */
        try {
            keys = Object.keys(queryParams);
        } catch (e) {
            console.log(e)
            return;
        }
        /* Checking for if keys value is an array and has at least one value */
        if (Array.isArray(keys) && keys.length > 0) {
            /* Looping through each of the value in the keys. (Essentially This loop will run for every key in the projected params object) */
            keys.forEach((item) => {
                /* We are checking if the key is valid by checking against validationKeyArray. if it matches we include it in the validParams object */
                const itemExists = validationKeyArray.includes(item)
                if (itemExists) {
                    Object.assign(validParameters, {[`${item}`]: queryParams[item]})
                }
            })
        }
        setInitialfilterParam(Object.keys(validParameters?.length > 0) ? {...validParameters} : null)
        setFilterParamForApiCall(Object.keys(validParameters?.length > 0) ? {...validParameters} : null)
    }, [queryParams])

    useEffect(() => {
        if (initialfilterParam) {
            setUrlIsEdited(true)
            if (initialfilterParam.page) {
                let page = initialfilterParam.page
                if (/^(0|[1-9][0-9]*)$/i.test(page)) {
                    setPage(parseInt(page))
                } else {
                    toast.error("Please provide only number as page count! Collecting data for first page..")
                }
            }
            setInitialfilterParam(null)
        }
    }, [initialfilterParam])

    useEffect(() => {
        if (urlIsEdited && Object.keys(errors).length === 0) {
            // Page will always be there
            GET_ROLE_LIST({page: page})
            setUrlIsEdited(false)
        }
    }, [urlIsEdited]);
    // ----- End: Section related to shareable link/handling query params ---- //


    useEffect(() => {
        const roleList = props.roleList;
        if (roleList && roleList.length > -1) {
            setRoleList(roleList.map((role, index) => {
                return {
                    ...role,
                    name: role.name,
                    created_at: role.created_at? moment(role.created_at).format("DD MMMM,YYYY hh:mm a"): "No Data Found"
                }
            }))
        } else {
            setRoleList(undefined)
        }
    }, [props.roleList]);

    useEffect(() => {
        if (props.errorMessageRoleListCollection) {
            showNotifications('error', props.errorMessageRoleListCollection)
        }
    }, [props.errorMessageRoleListCollection])

    useEffect(()=>{
        const organisations = props.organisations
        if (organisations && organisations.length >= 0) {
            setOrganizations(organisations.map((organisation) => {
                return {
                    name: organisation.name,
                    pk: organisation.pk
                }
            }));
        }
    },[props.organisations])

    const onChangePage = (event, newPage) => {
        setPage(newPage)
        props.history.push({
            search: 'page=' + page
        })
    }

    const gotToCreateRole = () => {
        props.history.push('/roles/create')
    }

    const showRoleDetails = (data) => {
        setRoleName(data.name)
        if (organizations) {
            for (let i=0; i<organizations.length; i++) {
                if (organizations[i].pk === data.organization_guid) {
                    setOrgName(organizations[i].name)
                    break
                }
            }
        }
        if (data.permissions.length > 0) {
            let permissions = []
            for (let i=0; i<data.permissions.length; i++) {
                permissions.push(data.permissions[i].action_title)
            }
            setPermittedActions(permissions)
        }
        setShowDetailsModal(true)
    }

    const closeDetailsModal = () => {
        setShowDetailsModal(false)
    }

    const permissionList = (perms) => {
        let permissions = []
        perms.map((permission) => {
            permissions.push(<li>{permission}</li>)
        })
        return <><ul>{permissions}</ul></>
    }

    const detailsModal = () => {
        return (
            <>
                <GenericModal
                    showModalHeader={true}
                    footer={true}
                    footerButtonSize={'sm'}
                    footerCloseButtonText={'Close'}
                    hideModal={closeDetailsModal}
                    centered={true}
                    modal={showDetailsModal}
                    noSubmitButton={true}
                    title={<>Details of the role: <SolTypography.Text
                        primary>{roleName}</SolTypography.Text></>}>
                    <p className={'no-padding'}><strong>Organization:</strong>&nbsp;{orgName? <SolTypography.Text primary>{orgName}</SolTypography.Text>:
                        <>N/A&nbsp;<span className={'text-danger'}>(Failed to collect organization name)</span></>}</p>
                    {permittedActions.length > 0? <>
                        <p><strong>Permissible actions for this role:</strong></p>
                        {permissionList(permittedActions)}
                    </>:<>
                        <p className={'no-padding'}>No permission is available for this role</p>
                    </>}
                </GenericModal>
            </>
        )
    }

    const goToRoleUpdatePage = (data) => {
        props.history.push({
            pathname: '/roles/update/',
            detailsData: data
        })
    }

    // ------- Begin: Duplicate role ------- //
    const initiateRoleDuplicateProcess = (roleData) => {
        setShowRoleDuplicationModal(true)
        setRoleDuplicationModalTitle(roleData.name)
        setGuidOfRole(roleData.pk)
    }

    const hideRoleDuplicationModal = () => {
        setShowRoleDuplicationModal(false)
        setRoleDuplicationModalTitle("")
        setGuidOfRole("")
    }

    const renderRoleDuplicationModal = () => {
        return <>
            <GenericModal
                size={'md'}
                showModalHeader={true}
                footer={false}
                hideModal={hideRoleDuplicationModal}
                modal={showRoleDuplicationModal}
                title={<h3>Duplicating <strong style={{color: "#F18D00"}}>{roleDuplicationModalTitle}</strong></h3>}>
                <Form onSubmit={handleSubmit(duplicateTheRole)}>
                    <div className={"row"}>
                        <div className={"col-md-12"}>
                            <Form.Group>
                                <div>
                                    <Form.Label>Name for new role<span className="text-danger">*</span></Form.Label>
                                </div>
                                <SolInput
                                    type={"text"}
                                    name={"name"}
                                    placeholder={"Type a name..."}
                                    autoComplete={"off"}
                                    ref={register({
                                        required: "Above information is required!",
                                        validate: {
                                            isValid: value => (!/^[a-zA-Z\s'-/]$/i.test(value) || !value) || "Non-allowed characters have been used!"
                                        }
                                    })}
                                />
                                {errors.name && <div className="text-danger">
                                    <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.name.message}</div>}
                            </Form.Group>
                            <hr/>
                        </div>
                    </div>
                    <div className={"row"}>
                        <div className={"col-md-12"}>
                            <Button variant={"warning"} size={"md"} type="submit"
                                    disabled={props.roleDuplicationProcessIsInProgress} className={'ml-3 float-right'}>
                                {props.roleDuplicationProcessIsInProgress? <>
                                    <Spinner animation={'border'} size={'sm'} variant={'light'}/>&nbsp;
                                </>:''}Save
                            </Button>
                            <Button variant={"outline-dark"} size={"md"} disabled={props.roleDuplicationProcessIsInProgress}
                                    onClick={hideRoleDuplicationModal} className={'float-right'}>
                                Cancel
                            </Button>
                        </div>
                    </div>
                </Form>
            </GenericModal>
        </>
    }

    const duplicateTheRole = (data) => {
        let payload = {
            "name": data.name
        }
        DUPLICATE_ROLE(guidOfRole, payload)
    }

    useEffect(() => {
        if (props.roleDuplicated && props.roleDuplicated === requestCycle.success) {
            toast.success("Role is duplicated successfully!")
            setReloadingRoleList(true)
            GET_ROLE_LIST()
            hideRoleDuplicationModal()
        }
    }, [props.roleDuplicated]);

    useEffect(() => {
        if (props.errorMessageRoleDuplicate) {
            showNotifications('error', props.errorMessageRoleDuplicate)
        }
    }, [props.errorMessageRoleDuplicate])
    // ------- End: Duplicate role ------- //

    return (
        <div>
        <ContentWrapper showCardHeader={false} showBackButton={false} isLoading={props.isLoading}
                        serverError={false} permissionDenied={false} pageTitle={"Roles"}
                        statusCode={props.statusCode}>

            <div className={'row'}>
                <div className={'col-md-12'}>
                    <Toolbar>
                        <Toolbar.Title>
                            <h1><strong>Roles</strong></h1>
                        </Toolbar.Title>
                        <Toolbar.ToolbarContainer>
                            <Toolbar.ToolbarContainer.Items>
                            </Toolbar.ToolbarContainer.Items>
                        </Toolbar.ToolbarContainer>
                    </Toolbar>
                </div>
            </div>
            <hr/>
            <div className={"row"}>
                <div className={"col-md-12"}>
                    {!props.listLoading && roleList?<>
                        <DataTableContainer>
                            <DataTable
                                language={language}
                                noDataAvailableMessageInEnglish={'No roles available'}
                                noDataAvailableMessageInBangla={'কোন ধরন নেই'}
                                columns={roleListColumns}
                                data={roleList}
                                showToolbar={false}
                                pagination={true}
                                pageSize={10}
                                actionColumnIndex={-1}
                                overrideCustomActions={true}
                                actions={actions}
                            />
                        </DataTableContainer>
                    </>: <>
                        {props.errorMessageRoleListCollection? <>
                            <span className={'d-flex justify-content-center align-items-center'}>
                                <h3>Role list collection process has failed</h3>
                            </span>
                        </>:<>
                            <Typography variant="h5">
                                <Skeleton className={"d-flex justify-content-center align-items-center"}
                                          variant="rect" sx={{ marginTop: '30px', color: '#F18D00' }} width={'100%'}
                                          height={570}>
                                    {reloadingRoleList? "Reloading":"Collecting"} role list ...
                                </Skeleton>
                            </Typography>
                            {/* TODO: Below without text loader is kept as an example. Will be used based on PM's decision */}
                            {/*<Skeleton variant={"rect"} width={'100%'} height={540} sx={{ marginTop: '30px' }}/>*/}
                        </>}
                    </>}
                </div>
            </div>
            {detailsModal()}
            {renderRoleDuplicationModal()}

        </ContentWrapper>

        <style jsx>{`
              .filter-animation {
                animation-name: breath-in;
                animation-duration: 40ms;
              }

              @keyframes breath-in {
                0% {
                  height: 0;
                  opacity: 0;
                }
                10% {
                  height: 10px;
                  opacity: 0.10;
                }
                20% {
                  height: 20px;
                  opacity: 0.20;
                }
                30% {
                  height: 30px;
                  opacity: 0.30;
                }
                40% {
                  height: 40px;
                  opacity: 0.40;
                }
                50% {
                  height: 50px;
                  opacity: 0.50;
                }
                60% {
                  height: 60px;
                  opacity: 0.60;
                }
                70% {
                  height: 70px;
                  opacity: 0.70;
                }
                80% {
                  height: 80px;
                  opacity: 0.80;
                }
                90% {
                  height: 90px;
                  opacity: 0.90;
                }
                100% {
                  height: auto;
                  opacity: 1.00;
                }
              }

              //@keyframes breath-out {
              //    0% { height: auto;  opacity: 1.00; }
              //    10% { height: 90px;  opacity: 0.90; }
              //    20% { height: 80px;  opacity: 0.80; }
              //    30% { height: 70px;  opacity: 0.70; }
              //    40% { height: 60px;  opacity: 0.60; }
              //    50% { height: 50px;  opacity: 0.50; }
              //    60% { height: 40px;  opacity: 0.40; }
              //    70% { height: 30px;  opacity: 0.30; }
              //    80% { height: 20px;  opacity: 0.20; }
              //    90% { height: 10px;  opacity: 0.10; }
              //    100% { height: 0; opacity: 0;  }  
              //}


            `}</style>
    </div>
    );
};

RoleList.propTypes = {};

const mapStateToProps = (state) => {
    return {
        language: state.auth.language,
        authorization: state.auth.authorizations,
        isLoading: state.rolesReducer.isLoading,
        tableLoading: state.rolesReducer.tableLoading,
        statusCode: state.rolesReducer.statusCode,
        roleList: state.rolesReducer.roleList,
        errorMessageRoleListCollection: state.rolesReducer.errorMessageRoleListCollection,
        listLoading: state.rolesReducer.listLoading,
        organisations: state.rolesReducer.organisations,
        roleDuplicationProcessIsInProgress: state.rolesReducer.roleDuplicationProcessIsInProgress,
        roleDuplicated: state.rolesReducer.roleDuplicated,
        errorMessageRoleDuplicate: state.rolesReducer.errorMessageRoleDuplicate
    }
}

export default connect(mapStateToProps, actions)(withRoles(RoleList));
