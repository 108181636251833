import React, {useEffect, useState} from 'react';
import ContentWrapper from "../../../../components/contentWrapper/contentWrapper";
import DataTable from "../../../../components/dataTable/DataTable";
import {Filter, Toolbar} from "../components";
import {connect} from "react-redux";
import {actions} from "../../actions";
import { actions as commonActions } from '../../../commonReduxActions/actions';
import {Button, Form, Spinner, OverlayTrigger, Popover, ListGroup, FormCheck} from "react-bootstrap";
import Image from 'react-bootstrap/Image'
import IconButton from "@material-ui/core/IconButton";
import FilterListIcon from "@material-ui/icons/FilterList";
import {SolInput} from "../../../../components/SolStyledComponents/components";
import {makeStyles} from "@material-ui/styles";
import {DataTableContainer} from '../../utils'
import {LoadingSpinner} from '../../../../components/LoadingSpinnerForDataTable';
import {Link} from "react-router-dom";
import {useSubheader} from "../../../../../_metronic/layout";
import {withRoles} from "../../../../router/SecuredRoute";
import Select, { components } from "react-select";
import {GenericModal} from "../../../../components/genericModal/genericModal";
import moment from "moment";
import {toast} from "react-toastify";
import {DayPickerRangeController} from "react-dates";
import {exportInExcel} from "../../../../utils/excelExport";
import {convertToAnotherLang} from "../../../../utils/convertLanguageOfNumbersWithCharacter";
import {toBengaliNumber} from "bengali-number";
import {isNullorUndefined} from "../../../../utils/utilityFunctions";
import {parseParams} from "../../../../utils/utilityFunctions";
import {Controller, useForm} from "react-hook-form";
import SolRadioInput from "../../../../components/solradioinput/SolRadioInput";
import { SolTypography } from '../../../../components';
import Stack from '@mui/material/Stack';
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import {useMediaQuery} from "react-responsive";
import {rentLogReducer} from "../../reducer";
import {showNotifications} from "../../../../utils/notification";
import DoNotDisturbAltRoundedIcon from '@mui/icons-material/DoNotDisturbAltRounded';
import InactiveBatteries from './inactiveBatteries';
import {ValueContainer} from '../../../../utils/reactSelectRelated/showCustomizedValueLabelForMultiSelect'
import {Option} from '../../../../utils/reactSelectRelated/showOptionsWithCheckbox'
import {customStylesForSingleSelectField} from '../../../../utils/reactSelectRelated/customStylesForSingleSelect'
import {customStylesForMultiSelectField} from '../../../../utils/reactSelectRelated/customStylesForMultiSelect'
import {groupSelectedOptions} from '../../../../utils/reactSelectRelated/groupSelectedOptionsForMultiSelect'
import {formulateTextFromSelectedItems} from '../../../../utils/multiSelectValueFormation/formatForExportedFile'
import {formulateApiRequestDataFromSelectedItems} from '../../../../utils/multiSelectValueFormation/formatForApiRequest'


const useStylesIconButton = makeStyles({
    root: {
        marginTop: '0',
        paddingTop: '4px',
    },
});

const RentLog = (props) => {
    const {register, errors, control, clearErrors, handleSubmit, reset, setValue, setError} = useForm();
    const [showFilter, setShowFilter] = useState(true);
    const [urlIsEdited, setUrlIsEdited] = useState(false);
    const [userIsPaginating, setUserIsPaginating] = useState(false);

    const [evs, setEvs] = useState([]);
    const [garages, setGarages] = useState([]);
    const [financiers, setFinanciers] = useState([]);
    const [branches, setBranches] = useState([]);
    const [branchFromUrl, setBranchFromUrl] = useState('');
    const [garageFromUrl, setGarageFromUrl] = useState("");
    const [financierFromUrl, setFinancierFromUrl] = useState("");
    const [evFromUrl, setEvFromUrl] = useState("");
    // Following variable may not seem needed as we have used setValue while saving garage selection
    // But we are using it to check if the provided garage phone number is valid or not while exporting rent log
    // If not valid, than garage phone number is not shown at the exported excel
    const [selectedGarage, setSelectedGarage] = useState("");
    const [selectedFinancier, setSelectedFinancier] = useState("");
    const [selectedEv, setSelectedEv] = useState("");
    const [branch, setBranch] = useState('');
    const [showDateSelectionField, setShowDateSelectionField] = useState(false);
    const [rentStatus, setRentStatus] = useState("");

    const [start, setStartDate] = useState(null);
    const [end, setEndDate] = useState(null);
    const [dateRange, setDateRange] = useState('');
    const [focusedInput, setFocusedInput] = useState('startDate');
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    const subHeader = useSubheader();


    // Driver details modal
    const [showDriverDetailsModal, setShowDriverDetailsModal] = useState(false);
    const [driverName, setDriverName] = useState('');
    const [driverNIDImageUrl, setDriverNIDImageUrl] = useState('');
    const [driverUserID, setDriverUserID] = useState('');
    const [driverLastEvID, setDriverLastEvID] = useState('');
    const [driverNID, setDriverNID] = useState('N/A');
    const [driverMobileNumber, setDriverMobileNumber] = useState('N/A');
    const [driverAddress, setDriverAddress] = useState('N/A');
    const [driverGarageName, setDriverGarageName] = useState('');
    const [driverGarageAddress, setDriverGarageAddress] = useState('');

    const [totalDeployedEV, setTotalDeployedEV] = useState(0);
    const [totalActiveEV, setTotalActiveEV] = useState(0);
    const [totalInactiveEV, setTotalInactiveEV] = useState(0);
    const [showActiveAndInactiveBatteryCount, setShowActiveAndInactiveBatteryCount] = useState(false);
    const [showInactiveBatteryModal, setShowInactiveBatteryModal] = useState(false);
    const [firstApiCallIsMade, setFirstApiCallIsMade] = useState(false);
    const [rentList, setRentList] = useState([]);
    const [garagesForValidation, setGaragesForValidation] = useState(undefined);
    const [page, setPage] = useState(1);
    const [filterApplied, setFilterApplied] = useState(false);
    const [authorization, setAuthorization] = useState(null);
    const [language, setLanguage] = useState(null);
    const [queryParams, setQueryParams] = useState(null);
    const [initialfilterParam, setInitialfilterParam] = useState(null);
    const [filterParamForApiCall, setFilterParamForApiCall] = useState(null);

    const invalidSerial = language === 'EN'? 'Invalid ID!':'ভুল আইডি!'

    const pageTitle = language === 'EN'? 'Battery Rent Log':'ব্যাটারী ভাড়ার সারমর্ম'

    const rentScheduleOptions = [
        {'label': 'Last 24 Hours', 'value': 'last_24'},
        {'label': 'Last Two Days', 'value': 'last_two_days'},
        {'label': 'Last Week', 'value': 'last_week'},
        {'label': 'Custom Date', 'value': 'custom_date'}
    ]

    const representRentStatus = (language, status) => {
        let represent = ''
        if (status === 'active') {
            represent = language === 'EN'? 'Active':'সক্রিয়'
        } else {
            represent = language === 'EN'? 'Completed':'শেষ হয়েছে'
        }
        return represent
    }

    const column1 = language === 'EN'? 'Rent ID': 'ভাড়ার আইডি'
    const column2 = language === 'EN'? 'Smart Battery': 'স্মার্ট ব্যাটারী'
    const column3 = language === 'EN'? 'Rented By': 'ভাড়া দিয়েছেন'
    const column4 = language === 'EN'? 'Rented On': 'ভাড়া দেওয়া হয়েছে'
    const column6 = language === 'EN'? 'Rent Ended On': 'ভাড়া শেষ হয়েছে'
    const column8 = language === 'EN'? 'Status': 'বর্তমান অবস্থা'

    // We have used 'rent_id' for the column two and three also for data existence checking
    // Because, there are checking during display of the data which rely on the existence of (either of) two or more values (ex. battery_details, driver_details etc.)
    // If we use one of the keys used at that checking, data will not be shown (N/A will be shown) if that key doesn't have a value even though other keys may have values
    // 'rent_id' is a common key, which will always have a value
    // - Noor Reza 25th October, 2023 6:46 PM
    const rentListColumns =  [
        {
            field: 'rent_id',
            title: column1,
            emptyValue: ()=>{
                return "N/A"
            },
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            // disableClick: true,
        },
        {
            field: 'rent_id',
            title: column2,
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            disableClick: true,
            render: (rowData)=>{
                if (rowData.ev_details || rowData.dongle_details || rowData.battery_details) {
                    return   <>
                        {rowData.ev_details? <>
                            <h6><strong>SB-{rowData.ev_details.device_sl}</strong></h6>
                        </>:''}
                        {rowData.dongle_details? <>
                            <small><strong>SOLdongle</strong></small><br/>
                            <small style={{whiteSpace: 'nowrap'}}><strong>#{rowData.dongle_details.serial_number},&nbsp;FW&nbsp;v{rowData.dongle_details.firmware_version},&nbsp;HW&nbsp;{rowData.dongle_details.hardware_version}</strong></small><br/>
                        </>:''}
                        {rowData.battery_details? <>
                            <small><strong>Battery&nbsp;#{rowData.battery_details.serial_number}</strong></small><br/>
                            {rowData.battery_details.battery_vendor && rowData.battery_details.nameplate_voltage && rowData.battery_details.nameplate_capacity? <>
                                <small style={{whiteSpace: 'nowrap'}}><strong>{rowData.battery_details.battery_vendor}&nbsp;{rowData.battery_details.nameplate_voltage}V,&nbsp;{rowData.battery_details.nameplate_capacity}Ah</strong></small>
                            </>:''}
                        </>:''}
                    </>

                } else {
                    return <>N/A</>
                }
            }
        },
        {
            field: 'rent_id',
            title: column3,
            emptyValue: ()=>{
                return "N/A"
            },
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                // textAlign: 'left'
                textAlign: 'center'
            },
            render: (rowData)=>{
                if (rowData.driver_details || rowData.garage_details || rowData.ev_details) {
                    return <>
                        <div className="d-flex p-3">
                            {rowData.ev_details && rowData.ev_details.ev_type? <>
                                <div className="d-flex flex-column align-items-center border-md rounded"
                                     style={{background: "#EBEBF0"}}>
                                    <div className="p-1 border-md rounded text-center" style={{background: "#DCDCE5",
                                        minWidth:"50px"}}><small>{rowData.ev_details.ev_sl}</small>
                                    </div>
                                    <div className="align-items-center align-self-stretch d-flex flex-grow-1 justify-content-center">
                                        {rowData.ev_details.ev_type === 'mishuk'?
                                            <img src={require('../../../../utils/asset/ic-mishuk.svg')} alt={"mishuk"}/>:
                                            rowData.ev_details.ev_type === 'easy_bike'?
                                                <img src={require('../../../../utils/asset/ic-easybike.svg')}
                                                     alt={"easy-bike"}/>:
                                                rowData.ev_details.ev_type === 'rickshaw'? <img
                                                    src={require('../../../../utils/asset/ic-mishuk.svg')}
                                                    alt={"rickshaw"}/>:''}
                                    </div>
                                </div>
                            </>:''}
                            <div className="px-3">
                                <p className="mb-0"><a onClick={() => showDriverDetails(rowData)}>{rowData.driver_details.driver_name}</a></p>
                                <p className="mb-0" style={{whiteSpace: "nowrap"}}>{rowData.garageName}</p>
                                <p className="mb-0" style={{whiteSpace: "nowrap"}}><small><strong>{rowData.garageUpazila && rowData.garageDistrict? rowData.garageUpazila + ", " + rowData.garageDistrict:rowData.garageDistrict}</strong></small></p>
                            </div>
                        </div>
                    </>
                } else {
                    return <>N/A</>
                }
            }
        },
        {
            field: 'start_time',
            title: column4,
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            render: (rowData)=>{
                let rentStartTimeMomentObj = moment(rowData.start_time)
                let rentExpireTimeMomentObj = moment(rowData.expire_time)
                let rentDurationInHours = rentExpireTimeMomentObj.diff(rentStartTimeMomentObj, 'hours')
                return   <div>
                    <span style={{whiteSpace: 'nowrap'}}>{rentStartTimeMomentObj.format("MMM D, YYYY hh:mm a")} for {rentDurationInHours}h</span><br/>
                    <small style={{whiteSpace: 'nowrap'}}><strong>{rowData.start_battery_charge}%&nbsp;SOC,&nbsp;{rowData.start_battery_voltage}V</strong></small><br/>
                    {rowData.rent_version? <>
                        <small style={{whiteSpace: 'nowrap'}}><strong>CholoGarage v{rowData.rent_version}</strong></small>
                    </>:null}
                </div>
            }
        },
        // {
        //     field: 'rent_amount',
        //     title: column5,
        //     cellStyle: {
        //         textAlign: 'right'
        //     },
        //     headerStyle: {
        //         textAlign: 'center'
        //     },
        //     disableClick: true,
        //     render: (rowData)=> {
        //         {/* Text color is taken from the column text color and same color code has been used in several other
        //          features --- Noor Reza, 15th October, 5:25 PM */}
        //         return <> {rowData.contract_type !== 'rent'? <><h6 style={{color: "#8C8C9B"}}><strong>N/A</strong></h6></>:<>
        //             <h6><strong>{new Intl.NumberFormat('en-IN').format(rowData.rent_amount.toFixed(2))}</strong></h6>
        //         </>}
        //         </>
        //     }
        // },
        {
            field: 'end_time',
            title: column6,
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            disableClick: true,
            render: (rowData) => {
                if (rowData.status === 'done') {
                    let rentEndedByUser = rowData.last_user_name? rowData.last_user_name:''
                    return  <>
                        <span style={{whiteSpace: 'nowrap'}}>{moment(rowData.end_time).format("MMM D, YYYY hh:mm a")} {rentEndedByUser? 'by ' + rentEndedByUser:''}</span><br/>
                        {rentEndedByUser !== 'System'? <>
                            <small style={{whiteSpace: 'nowrap'}}><strong>{rowData.end_battery_charge}%&nbsp;SOC,&nbsp;{rowData.end_battery_voltage}V</strong></small>
                        </>:''}
                    </>
                } else {
                    let rentExpireTimeMomentObj = moment(rowData.expire_time)
                    let presentTimeMomentOj = moment()
                    let rentBeingActiveFor = rentExpireTimeMomentObj.diff(presentTimeMomentOj, 'minutes')
                    let rentBeingActiveForHoursMinutesBreakDown = Math.floor(rentBeingActiveFor / 60) + ':' + rentBeingActiveFor % 60
                    let rentTimeRemainingText = rentBeingActiveForHoursMinutesBreakDown.split(':')[1] !== '00'?
                        rentBeingActiveForHoursMinutesBreakDown.split(':')[0] + 'h ' +
                        rentBeingActiveForHoursMinutesBreakDown.split(':')[1] + 'm remaining':
                        rentBeingActiveForHoursMinutesBreakDown.split(':')[0] + 'h remaining'
                    return <>
                        <span style={{whiteSpace: 'nowrap'}}>{rentTimeRemainingText}</span>
                    </>
                }
            }
        },
        // {
        //     field: 'rent_payment_status',
        //     title: column7,
        //     emptyValue: ()=>{
        //         return "N/A"
        //     },
        //     cellStyle: {
        //         paddingLeft: '0',
        //         textAlign: 'left'
        //     },
        //     headerStyle: {
        //         paddingLeft: '0',
        //         textAlign: 'left'
        //     },
        //     disableClick: true,
        //     render: (rowData)=> {
        //         if (rowData.rent_payment_status === 'due') {
        //             return <>
        //                 <Button variant={'outline-danger'} size={'sm'}>Due</Button>
        //             </>
        //         } else {
        //             return <>
        //                 <Button variant={'outline-success'} size={'sm'}>Paid</Button>
        //             </>
        //         }
        //     }
        // },
        {
            field: 'status',
            title: column8,
            emptyValue: ()=>{
                return "N/A"
            },
            disableClick: true,
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            render: (rowData)=> {
                if (rowData.status === 'done') {
                    return <>
                        <Button variant={'outline-success'} size={'sm'}>Completed</Button>
                    </>
                } else {
                    return <>
                        <Button variant={'outline-danger'} size={'sm'}>Active</Button>
                    </>
                }
            }
        }
    ]

    const {
        COLLECT_RENT_LIST_V1,
        GET_RENT_LIST_TO_EXPORT_V1,
        COLLECT_GARAGE_LIST,
        GET_GARAGE_LIST,
        GET_BRANCHES_LIST,
        COLLECT_DRIVER_DETAILS,
        GET_ORGANISATIONS,
        COLLECT_EV_LIST,
        RETURN_TO_INITIAL_STATE_FOR_DRIVER_DETAILS_MODAL
    } = props;
    const classes = useStylesIconButton();

    // At the following inactive battery icon inline style, value of 'color' is taken from Figma
    // Value of 'backgroundColor' is the color code used for the failed events at the Event History at the EV search page
    // - Noor Faizur Reza, 15th October, 3:57 PM
    const inactiveBatteryIconStyle = {
        color: '#CB152B',
        backgroundColor: '#FDEDED'
    }

    const collectLast24HoursData = () => {
        let todaysDateTime = moment().format("YYYY-MM-DDTHH:mm:ss")
        let twentyFourHoursEarlier = moment(todaysDateTime).subtract(24, 'hours').format("YYYY-MM-DDTHH:mm:ss")
        let from_date = props.language === 'EN'? twentyFourHoursEarlier:convertToAnotherLang(twentyFourHoursEarlier)
        let to_date = props.language === 'EN'? todaysDateTime:convertToAnotherLang(todaysDateTime)
        setStartDate(moment(from_date, "YYYY-MM-DDTHH:mm:ss"))
        setEndDate(moment(to_date, "YYYY-MM-DDTHH:mm:ss"))
    }

    const collectLastTwoDaysData = (makeApiCall) => {
        let todaysDateTime = moment().format("YYYY-MM-DDTHH:mm:ss")
        let oneDayEarlier = moment(todaysDateTime).subtract(1, 'days').format("YYYY-MM-DD")
        let from_date = props.language === 'EN'? oneDayEarlier + 'T00:00:00':convertToAnotherLang(oneDayEarlier) + 'T00:00:00'
        let to_date = props.language === 'EN'? todaysDateTime:convertToAnotherLang(todaysDateTime)
        setStartDate(moment(from_date, "YYYY-MM-DDTHH:mm:ss"))
        setEndDate(moment(to_date, "YYYY-MM-DDTHH:mm:ss"))
        if (makeApiCall) {
            setPage(1)
            setValue('rent_schedule', rentScheduleOptions[1])
            let searchText = 'page=1&from_date=' + from_date + '&to_date=' + to_date
            props.history.push({
                search: searchText
            })
        }
    }

    const collectLastSevenDaysData = () => {
        let todaysDateTime = moment().format("YYYY-MM-DDTHH:mm:ss")
        let sixDaysEarlier = moment(todaysDateTime).subtract(6, 'days').format("YYYY-MM-DD")
        let from_date = props.language === 'EN'? sixDaysEarlier + 'T00:00:00':convertToAnotherLang(sixDaysEarlier) + 'T00:00:00'
        let to_date = props.language === 'EN'? todaysDateTime:convertToAnotherLang(todaysDateTime)
        setStartDate(moment(from_date, "YYYY-MM-DDTHH:mm:ss"))
        setEndDate(moment(to_date, "YYYY-MM-DDTHH:mm:ss"))
    }

    useEffect(() => {
        setAuthorization(props.authorization)
        setLanguage(props.language)
        subHeader.setActionButtons(null)
        subHeader.setBreadCrumbComponent(null)
        if (!props.history.location.search) {
            collectLastTwoDaysData(true)
        }
        setFirstApiCallIsMade(true)
        COLLECT_GARAGE_LIST()
        GET_BRANCHES_LIST()
        if (props.isSolshareUser) {
            GET_ORGANISATIONS()
        }
        COLLECT_EV_LIST({status: 'active'})
        return ()=>{
            props.RETURN_TO_INITIAL_STATE();
        }
    }, []);

    useEffect(() => {
        if (props.branches && Array.isArray(props.branches)) {
            let branchesForSelection = []
            const branchList = props.branches.map((branch) => {
                if (branchFromUrl) {
                    let branchesFromUrl = branchFromUrl.split(',')
                    if (branchesFromUrl.includes(branch.pk)) {
                        branchesForSelection.push({
                            ...branch,
                            label: branch.name,
                            value: branch.pk
                        })
                    }
                }
                return {
                    ...branch,
                    value: branch.pk,
                    label: branch.name
                }
            })
            if (branchesForSelection.length > 0) {
                setBranch(branchesForSelection)
                setBranches(groupSelectedOptions(branchesForSelection, branchList))
            } else {
                setBranches(branchList)
            }
        }
    }, [props.branches])

    useEffect(() => {
        if (props.evs && props.evs.length > -1) {
            const evs = props.evs
            setEvs(evs.map((ev) => {
                if (evFromUrl && evFromUrl === ev.pk) {
                    let evForSelection = ev
                    evForSelection['label'] = evForSelection.serial_number
                    evForSelection['value'] = evForSelection.pk
                    setValue('ev', evForSelection)
                    setSelectedEv(evForSelection)
                }
                return {
                    ...ev,
                    value: ev.pk,
                    label: ev.serial_number
                }
            }))
        }
    }, [props.evs])

    useEffect(() => {
        if (props.errorMessageForEvListCollection) {
            showNotifications('error', props.errorMessageForEvListCollection)
        }
    }, [props.errorMessageForEvListCollection])

    useEffect(() => {
        if (props.organisations && props.organisations.length > -1) {
            const organizations = props.organisations
            let financiersForSelection = []
            const financierList = organizations.map((organization) => {
                if (financierFromUrl) {
                    let financiersFromUrl = financierFromUrl.split(',')
                    if (financiersFromUrl.includes(organization.pk)) {
                        financiersForSelection.push({
                            ...organization,
                            label: organization.name,
                            value: organization.pk
                        })
                    }
                }
                return {
                    ...organization,
                    value: organization.pk,
                    label: organization.name
                }
            })
            if (financiersForSelection.length > 0) {
                setSelectedFinancier(financiersForSelection)
                setFinanciers(groupSelectedOptions(financiersForSelection, financierList))
            } else {
                setFinanciers(financierList)
            }
        } else {
            setFinanciers([])
        }
    }, [props.organisations])

    const showDriverDetails = (data) => {
        setDriverName(data.driver_details.driver_name)
        setDriverMobileNumber(data.driver_details.driver_phone)
        setDriverLastEvID(data.ev_details.ev_sl)
        setDriverGarageName(data.garageName)
        let garageAddress = ''
        if (data.garageDistrict && data.garageUpazila) {
            garageAddress = data.garageUpazila + ', ' + data.garageDistrict
        } else if (!data.garageDistrict && data.garageUpazila) {
            garageAddress = data.garageUpazila
        } else if (data.garageDistrict && !data.garageUpazila) {
            garageAddress = data.garageDistrict
        }
        setDriverGarageAddress(garageAddress)
        RETURN_TO_INITIAL_STATE_FOR_DRIVER_DETAILS_MODAL()
        setShowDriverDetailsModal(true)
        COLLECT_DRIVER_DETAILS(data['driver_details']['driver_phone'])
    }

    const hideDriverDetailsModal = () => {
        setShowDriverDetailsModal(false)
    }

    const resetFilterValues = () => {
        setValue('smart_battery_id', '')
        setValue('dongle_id', '')
        setValue('driver', '')
        setValue('ev', '')
        setSelectedEv('')

        setSelectedGarage('')
        setGarageFromUrl('')

        if (props.isSolshareUser && selectedFinancier) {
            setSelectedFinancier('')
            setFinancierFromUrl('')
            GET_BRANCHES_LIST()
        }

        setStartDate(null)
        setEndDate(null)
        setValue('rent_schedule', '')
        setShowDateSelectionField(false)
        setShowActiveAndInactiveBatteryCount(false)
        setValue('rent_status', '')
        setRentStatus('')
        setValue('payment_status', '')

        setBranch( '')
        setBranchFromUrl( '')
    }

    const filterToggle = () => {
        setShowFilter(!showFilter)
    }

    useEffect(() => {
        if (props.rentList) {
            setTotalDeployedEV(props.rentList.total_deployed_device)
            setTotalActiveEV(props.rentList.total_active_device)
            setTotalInactiveEV(props.rentList.total_inactive_device)
            const rentList = props.rentList.results;
            if (rentList && rentList.length > -1) {
                setRentList(rentList.map((rent, index) => {
                    // let paidAmount = new Intl.NumberFormat('en-IN').format(rent.pay_amount)
                    let garageName = rent.garage_details?.garage_name? rent.garage_details.garage_name:''
                    let garageDistrict = rent.garage_details?.garage_district? rent.garage_details.garage_district:''
                    let garageUpazila = rent.garage_details?.garage_upazila? rent.garage_details.garage_upazila:''
                    if (!firstApiCallIsMade && garagesForValidation && garagesForValidation.length > 0) {
                        let filteredData = garagesForValidation.filter(garage => garage.pk === rent.garage_guid)
                        if (filteredData.length > 0) {
                            garageName = filteredData[0].name
                            garageDistrict = filteredData[0].district
                            garageUpazila = filteredData[0].upazila
                        }
                    } else if (firstApiCallIsMade && garages && garages.length > 0) {
                        let filteredData = garages.filter(garage => garage.pk === rent.garage_guid)
                        if (filteredData.length > 0) {
                            garageName = filteredData[0].name
                            garageDistrict = filteredData[0].district
                            garageUpazila = filteredData[0].upazila
                        }
                    }
                    return {
                        ...rent,
                        garageName: garageName,
                        garageDistrict: garageDistrict,
                        garageUpazila: garageUpazila
                    }
                }))
            }
            setFirstApiCallIsMade(false)
        } else {
            setRentList([])
        }
    }, [props.rentList, garages, garagesForValidation]);

    useEffect(() => {
        if (props.errorMessageForRentListCollection) {
            showNotifications('error', props.errorMessageForRentListCollection)
        }
    }, [props.errorMessageForRentListCollection])

    useEffect(() => {
        if (props.errorMessageForRentListExport) {
            showNotifications('error', props.errorMessageForRentListExport)
        }
    }, [props.errorMessageForRentListExport])

    useEffect(() => {
        if (props.rentListForExport) {
            let rents = props.rentListForExport.results
            let presentTime = moment()
            let totalRentText = language === 'EN'? "Total Rent: " + rents.length:"মোট ভাড়া দেওয়া হয়েছে: " + toBengaliNumber(rents.length) + " বার"
            let exportText = language === 'EN'? "Exported At: " + presentTime.format("Do MMMM,YYYY hh:mm A"):"এক্সেল ফাইল ডাউনলোডের সময়: " + presentTime.format("Do MMMM,YYYY hh:mm A")
            let dongleColumn = language === 'EN'? 'SOLdongle':'ডঙ্গল'
            let batteryColumn = language === 'EN'? 'Battery':'ব্যাটারী'
            let appVersionColumn = language === 'EN'? 'CholoGarage App':'মোবাইল অ্যাপ ভার্সন'
            let garageNameColumn = language === 'EN'? 'Garage Name':'গ্যারেজের নাম'
            let garageIdColumn = language === 'EN'? 'Garage ID':'গ্যারেজের আইডি'
            let garageAddressColumn = language === 'EN'? 'Garage Address':'গ্যারেজের ঠিকানা'
            let rentStartTimeSocColumn = language === 'EN'? 'Rent Start Time SOC(%)':'ভাড়া দেওয়ার সময় SOC(%)'
            let rentStartTimeVoltageColumn = language === 'EN'? 'Rent Start Time Voltage(v)':'ভাড়া দেওয়ার সময় ভোল্টেজ(v)'
            let rentEndedByColumn = language === 'EN'? 'Rent Ended By':'ভাড়া বন্ধ করেছেন'
            let rentEndTimeSocColumn = language === 'EN'? 'Rent End Time SOC(%)':'ভাড়া বন্ধ করার সময় SOC(%)'
            let rentEndTimeVoltageColumn = language === 'EN'? 'Rent End Time Voltage(v)':'ভাড়া বন্ধ করার সময় ভোল্টেজ(v)'
            let paymentStatusColumn = language === 'EN'? 'Payment Status':'পেমেন্টের অবস্থা'
            let rent_schedule_from_date, rent_schedule_to_date
            let excelRows = [
                [pageTitle, "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""],
                [totalRentText, "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""],
                [exportText, "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""],
                ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""],
                ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""],
                [column1, column8, column2, dongleColumn, batteryColumn, appVersionColumn, column3, garageNameColumn, garageIdColumn,
                    garageAddressColumn, column4, rentStartTimeSocColumn, rentStartTimeVoltageColumn, column6,
                    rentEndedByColumn, rentEndTimeSocColumn, rentEndTimeVoltageColumn]
            ]
            if (filterApplied) {
                if (start && end) {
                    rent_schedule_from_date = language === 'EN'? moment(start).format("MMM DD, YYYY"):convertToAnotherLang(moment(start).format("MMM DD, YYYY"))
                    rent_schedule_to_date = language === 'EN'? moment(end).format("MMM DD, YYYY"):convertToAnotherLang(moment(end).format("MMM DD, YYYY"))
                    let text = "Rent Schedule: " + rent_schedule_from_date + " - " + rent_schedule_to_date
                    excelRows.splice(1, 0, [text, "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""])
                }
                if (filterParamForApiCall.device_sl) {
                    let text = "Smart Battery ID: " + filterParamForApiCall.device_sl
                    excelRows.splice(1, 0, [text, "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""])
                }
                if (filterParamForApiCall.dongle_sl) {
                    let text = "SOLdongle ID: " + filterParamForApiCall.dongle_sl
                    excelRows.splice(1, 0, [text, "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""])
                }
                if (filterParamForApiCall.driver_name) {
                    let text = "Driver: " + filterParamForApiCall.driver_name
                    excelRows.splice(1, 0, [text, "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""])
                }
                if (filterParamForApiCall.ev_guid && selectedEv) {
                    let text = "Ev: " + selectedEv.label
                    excelRows.splice(1, 0, [text, "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""])
                }
                if (filterParamForApiCall.garage_guid && selectedGarage) {
                    let text = formulateTextFromSelectedItems('Garage', selectedGarage)
                    excelRows.splice(1, 0, [text, "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""])
                }
                if (filterParamForApiCall.financier_guid && selectedFinancier) {
                    let text = formulateTextFromSelectedItems('Financier', selectedFinancier)
                    excelRows.splice(1, 0, [text, "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""])
                }
                if (filterParamForApiCall.branch_guid && branch) {
                    // To make correct plural form of 'Branch', we have added an extra validation at the 'formulateTextFromSelectedItems' function
                    // Correct plural form: Branches
                    let text = formulateTextFromSelectedItems('Branch', branch)
                    excelRows.splice(1, 0, [text, "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""])
                }
                if (filterParamForApiCall.status) {
                    let text = "Rent Status: " + representRentStatus(language, filterParamForApiCall.status)
                    excelRows.splice(1, 0, [text, "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""])
                }
            }

            for (let i=0; i<rents.length; i++) {
                let status = representRentStatus(language, rents[i].status)
                // let start_time = moment(garages[i].start_time).format("DD MMM,YYYY hh:mm A")
                // let end_time = garages[i].end_time? moment(garages[i].end_time).format("DD MMM,YYYY hh:mm A"):"-"
                let garageName = rents[i].garage_details?.garage_name? rents[i].garage_details.garage_name:''
                let garageDistrict = rents[i].garage_details?.garage_district? rents[i].garage_details.garage_district:''
                let garageUpazila = rents[i].garage_details?.garage_upazila? rents[i].garage_details.garage_upazila:''
                if (!firstApiCallIsMade && garagesForValidation && garagesForValidation.length > 0) {
                    let filteredData = garagesForValidation.filter(garage => garage.pk === rents[i].garage_guid)
                    if (filteredData.length > 0) {
                        garageName = filteredData[0].name
                        garageDistrict = filteredData[0].district
                        garageUpazila = filteredData[0].upazila
                    }
                } else if (firstApiCallIsMade && garages && garages.length > 0) {
                    let filteredData = garages.filter(garage => garage.pk === rents[i].garage_guid)
                    if (filteredData.length > 0) {
                        garageName = filteredData[0].name
                        garageDistrict = filteredData[0].district
                        garageUpazila = filteredData[0].upazila
                    }
                }
                excelRows.push(
                    [
                        rents[i].rent_id,
                        status,
                        rents[i].ev_details && rents[i].ev_details.device_sl? 'SB-' + rents[i].ev_details.device_sl:'',
                        rents[i].dongle_details && rents[i].dongle_details.serial_number? rents[i].dongle_details.serial_number:'',
                        rents[i].battery_details && rents[i].battery_details.serial_number? rents[i].battery_details.serial_number:'',
                        'v' + rents[i].rent_version,
                        rents[i].driver_details && rents[i].driver_details.driver_name? rents[i].driver_details.driver_name:'',
                        garageName,
                        rents[i].garage_details && rents[i].garage_details.garage_id? rents[i].garage_details.garage_id:'',
                        garageUpazila && garageDistrict? garageUpazila + ", " + garageDistrict:garageDistrict,
                        moment(rents[i].start_time).format("MMM D, YYYY hh:mm a"),
                        rents[i].start_battery_charge,
                        rents[i].start_battery_voltage,
                        // rents[i].contract_id? 'N/A':rents[i].rent_amount,
                        rents[i].end_time? moment(rents[i].end_time).format("MMM D, YYYY hh:mm a"):'-',
                        rents[i].last_user_name,
                        rents[i].end_battery_charge,
                        rents[i].end_battery_voltage
                    ])
            }

            let noDataAvailableText =  language === 'EN'? "No data available":"কোন তথ্য নেই"
            rents.length > 0? excelRows.push(
                ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""],
                ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""]
            ):excelRows.push(["", "", "", "", "", "", "", "", noDataAvailableText, "", "", "", "", "", "", "", ""])
            let fileName = 'battery_rent_logs_' + moment().format("DD_MM_YYYY")
            exportInExcel(excelRows, fileName)
        }
    }, [props.rentListForExport]);

    useEffect(() => {
        if (props.driverDetails) {
            const driverDetails = props.driverDetails
            setDriverNIDImageUrl(driverDetails.identity_doc_picture)
            setDriverNID(driverDetails.identity_number)
            let driverAddressText = ''
            let village = driverDetails.village
            let upazila = driverDetails.upazila
            if (village && upazila) {
                driverAddressText = village + ', ' + upazila
            } else if (!village && upazila) {
                driverAddressText = upazila
            } else if (village && !upazila) {
                driverAddressText = village
            }
            if (driverAddressText) {
                setDriverAddress(driverAddressText)
            }
        } else {
            setDriverNIDImageUrl('')
            setDriverNID('')
            setDriverAddress('')
        }
    }, [props.driverDetails]);

    const onChangePage = (event, newPage) => {
        setPage(newPage)
        setUserIsPaginating(true)
        let searchText = 'page=' + newPage
        if (filterApplied) {
            if (start && end) {
                let from_date = language === 'EN'? moment(start).format("YYYY-MM-DDTHH:mm:ss"):convertToAnotherLang(moment(start).format("YYYY-MM-DDTHH:mm:ss"))
                let to_date = language === 'EN'? moment(end).format("YYYY-MM-DDTHH:mm:ss"):convertToAnotherLang(moment(end).format("YYYY-MM-DDTHH:mm:ss"))
                searchText += '&from_date=' + from_date + '&to_date=' + to_date
            }
            if (filterParamForApiCall.device_sl) {
                searchText += '&device_sl=' + filterParamForApiCall.device_sl
            }
            if (filterParamForApiCall.dongle_sl) {
                searchText += '&dongle_sl=' + filterParamForApiCall.dongle_sl
            }
            if (filterParamForApiCall.driver_name) {
                searchText += '&driver_name=' + filterParamForApiCall.driver_name
            }
            if (filterParamForApiCall.ev_guid) {
                searchText += '&ev_guid=' + filterParamForApiCall.ev_guid
            }
            if (filterParamForApiCall.garage_guid) {
                searchText += '&garage_guid=' + filterParamForApiCall.garage_guid
            }
            if (filterParamForApiCall.financier_guid) {
                searchText += '&financier_guid=' + filterParamForApiCall.financier_guid
            }
            if (filterParamForApiCall.branch_guid) {
                searchText += '&branch_guid=' + filterParamForApiCall.branch_guid
            }
            if (filterParamForApiCall.status) {
                searchText += '&status=' + filterParamForApiCall.status
            }
            props.history.push({
                search: searchText
            })
        } else {
            props.history.push({
                search: searchText
            })
        }
    }

    useEffect(() => {
        if (urlIsEdited && Object.keys(errors).length === 0) {
            if (!userIsPaginating) {
                setTotalDeployedEV(0)
                setTotalActiveEV(0)
                setTotalInactiveEV(0)
            }
            // Page will always be there
            let filter = {page: page}
            // TODO: Following can be taken into a function as it has been reused in the export API call also
            // Sometimes there will not be dates if user doesn't select dates after "Reset Filter" button click
            if (start && end) {
                let from_date = language === 'EN'? moment(start).format("YYYY-MM-DDTHH:mm:ss"):convertToAnotherLang(moment(start).format("YYYY-MM-DDTHH:mm:ss"))
                let to_date = language === 'EN'? moment(end).format("YYYY-MM-DDTHH:mm:ss"):convertToAnotherLang(moment(end).format("YYYY-MM-DDTHH:mm:ss"))
                filter['from_date'] = from_date
                filter['to_date'] = to_date
            }
            if (filterParamForApiCall.device_sl) {
                filter['device_sl'] = filterParamForApiCall.device_sl
            }
            if (filterParamForApiCall.dongle_sl) {
                filter['dongle_sl'] = filterParamForApiCall.dongle_sl
            }
            if (filterParamForApiCall.driver_name) {
                filter['driver_name'] = filterParamForApiCall.driver_name
            }
            if (filterParamForApiCall.ev_guid) {
                filter['ev_guid'] = filterParamForApiCall.ev_guid
            }
            if (filterParamForApiCall.garage_guid) {
                filter['garage_guid'] = filterParamForApiCall.garage_guid
            }
            if (filterParamForApiCall.financier_guid) {
                filter['financier_guid'] = filterParamForApiCall.financier_guid
            }
            if (filterParamForApiCall.status) {
                filter['status'] = filterParamForApiCall.status
            }
            if (filterParamForApiCall.branch_guid) {
                filter['branch_guid'] = filterParamForApiCall.branch_guid
            }
            if (Object.keys(filter).length > 1) {
                setFilterApplied(true)
            } else {
                setFilterApplied(false)
            }

            COLLECT_RENT_LIST_V1(filter)
            setUserIsPaginating(false)
            if (!firstApiCallIsMade) {
                GET_GARAGE_LIST()
            }
            setUrlIsEdited(false)
        }
    }, [start, end, urlIsEdited]);

    const handleChangeRentStatus = (event) => {
        setRentStatus(event.target.value)
    }

    const filterTheList = (data) => {
        setPage(1)
        let searchText = 'page=1'
        if (data.rent_schedule && start && end) {
            let from_date = moment(start).format("YYYY-MM-DDTHH:mm:ss")
            let to_date = moment(end).format("YYYY-MM-DDTHH:mm:ss")
            searchText += '&from_date=' + from_date + '&to_date=' + to_date
        }
        if (data.smart_battery_id) {
            searchText += '&device_sl=' + data.smart_battery_id
        }
        if (data.dongle_id) {
            searchText += '&dongle_sl=' + data.dongle_id
        }
        if (data.driver) {
            searchText += '&driver_name=' + data.driver
        }
        if (data.ev) {
            searchText += '&ev_guid=' + data.ev.value
        }
        if (selectedGarage) {
            searchText += '&garage_guid=' + formulateApiRequestDataFromSelectedItems(selectedGarage)
        }
        if (selectedFinancier) {
            searchText += '&financier_guid=' + formulateApiRequestDataFromSelectedItems(selectedFinancier)
        }
        if (rentStatus) {
            searchText += '&status=' + rentStatus
        }
        if (branch) {
            searchText += '&branch_guid=' + formulateApiRequestDataFromSelectedItems(branch)
        }
        props.history.push({
            search: searchText
        })
    }

    const resetFilter = () => {
        resetFilterValues()
        clearErrors()
        if (filterApplied) {
            // Resetting the Rent Log
            setPage(1)
            props.history.push({
                search: 'page=1'
            })
        }
    }

    const validateIfFuture = (keyword) => {
        const date = moment(keyword, 'YYYY-MM-DDTHH:mm:ss')
        if (date.isValid()) {
            const todayDate = moment()
            return date.isAfter(todayDate, 'day')
        } else {
            return false
        }
    }

    const validateIfToDateIsOlderThanFromDate = (from, to) => {
        const fromDate = moment(from, 'YYYY-MM-DDTHH:mm:ss')
        const toDate = moment(to, 'YYYY-MM-DDTHH:mm:ss')
        if (fromDate.isValid() && toDate.isValid()) {
            return fromDate.isAfter(toDate, 'day')
        } else {
            return false
        }
    }

    const validationKeyArray = ['page', 'device_sl', 'dongle_sl', 'driver_name', 'garage_guid',
        'financier_guid', 'ev_guid', 'from_date', 'to_date', 'status', 'branch_guid']

    useEffect(() => {
        let temp = null;
        try {
            temp = parseParams(props.history?.location?.search);
            if (Object.keys(temp)?.length > -1) {
                /* Set the projected filter lists. Requires validation */
                setQueryParams(temp);
            }
        } catch (e) {
            console.debug(e);
        }
    },[props.history.location])

    useEffect(() => {
        let keys = null;
        const validParameters = {};
        /* Only these keys in the projected params are valid in the projected params */

        /* Getting the keys in the projected params */
        try {
            keys = Object.keys(queryParams);
        } catch (e) {
            console.log(e)
            return;
        }
        /* Checking for if keys value is an array and has at least one value */
        if (Array.isArray(keys) && keys.length > 0) {
            /* Looping through each of the value in the keys. (Essentially This loop will run for every key in the projected params object) */
            keys.forEach((item) => {
                /* We are checking if the key is valid by checking against validationKeyArray. if it matches we include it in the validParams object */
                const itemExists = validationKeyArray.includes(item)
                if (itemExists) {
                    Object.assign(validParameters, {[`${item}`]: queryParams[item]})
                }
            })
        }
        setInitialfilterParam(Object.keys(validParameters?.length > 0) ? {...validParameters} : null)
        setFilterParamForApiCall(Object.keys(validParameters?.length > 0) ? {...validParameters} : null)
    }, [queryParams])

    useEffect(() => {
        if (!isNullorUndefined(initialfilterParam)) {
            setUrlIsEdited(true)
            if (initialfilterParam.page) {
                let page = initialfilterParam.page
                if (/^(0|[1-9][0-9]*)$/i.test(page)) {
                    setPage(parseInt(page))
                } else {
                    toast.error("Please provide only number as page count! Collecting data for first page..")
                }
            }
            if (initialfilterParam.device_sl) {
                setValue('smart_battery_id', initialfilterParam.device_sl, {shouldValidate: true})
            }
            if (initialfilterParam.dongle_sl) {
                setValue('dongle_id', initialfilterParam.dongle_sl, {shouldValidate: true})
            }
            if (initialfilterParam.driver_name) {
                let driverName = initialfilterParam.driver_name.split("%20").join(" ")
                setValue('driver', driverName, {shouldValidate: true})
                filterParamForApiCall['driver_name'] = driverName
            }
            if (initialfilterParam.ev_guid) {
                setEvFromUrl(initialfilterParam.ev_guid)
            } else {
                setEvFromUrl('')
            }
            if (initialfilterParam.garage_guid) {
                setGarageFromUrl(initialfilterParam.garage_guid)
            } else {
                setGarageFromUrl('')
            }
            if (initialfilterParam.financier_guid) {
                setFinancierFromUrl(initialfilterParam.financier_guid)
            } else {
                setFinancierFromUrl('')
            }
            if (initialfilterParam.branch_guid) {
                setBranchFromUrl(initialfilterParam.branch_guid)
            } else {
                setBranchFromUrl('')
            }
            if (initialfilterParam.from_date && initialfilterParam.to_date) {
                if (validateIfFuture(initialfilterParam.from_date) || validateIfFuture(initialfilterParam.to_date)) {
                    setValue('rent_schedule', '')
                    setError('rent_schedule', {type: 'custom', message: language === 'EN'? "Future date can't be selected!":'ভবিষ্যতের তারিখ/সময় বাছাই করা যাবে না!'})
                } else if (validateIfToDateIsOlderThanFromDate(initialfilterParam.from_date, initialfilterParam.to_date)) {
                    setValue('rent_schedule', '')
                    setError('rent_schedule', {type: 'custom', message: language === 'EN'? 'From date should be older!':'শুরুর তারিখটি অবশ্যই শেষ তারিখের আগের তারিখ হতে হবে!'})
                } else {
                    let fromDate = moment(initialfilterParam.from_date, "YYYY-MM-DDTHH:mm:ss")
                    let toDate = moment(initialfilterParam.to_date, "YYYY-MM-DDTHH:mm:ss")
                    let todaysDate = moment().format("DD")
                    setStartDate(fromDate)
                    setEndDate(toDate)
                    clearErrors('rent_schedule')
                    let differenceInSeconds = parseInt(toDate.diff(fromDate, 'seconds', true))
                    if (differenceInSeconds === 86400) {
                        // Difference is exactly 24 hour
                        if (toDate.format("DD") === todaysDate) {
                            // Last 24 hour option gets selected
                            setValue('rent_schedule', rentScheduleOptions[0])
                            setShowDateSelectionField(false)
                            setShowActiveAndInactiveBatteryCount(true)
                        } else {
                            // Custom date option gets selected
                            // As we are showing date selection field, we are updating the date values according to the expected behavior
                            setStartDate(moment(fromDate.format("YYYY-MM-DD") + "T00:00:00", "YYYY-MM-DDTHH:mm:ss"))
                            setEndDate(moment(toDate.format("YYYY-MM-DD") + "T23:59:59", "YYYY-MM-DDTHH:mm:ss"))
                            setValue('rent_schedule', rentScheduleOptions[3])
                            setShowDateSelectionField(true)
                            setShowActiveAndInactiveBatteryCount(false)
                        }
                    } else if (86400 < differenceInSeconds && differenceInSeconds <= 172800) {
                        // Difference is more than 24 hours but less or equal to 48 hours
                        if (toDate.format("DD") === todaysDate) {
                            // Last Two Days option gets selected
                            setValue('rent_schedule', rentScheduleOptions[1])
                            setShowDateSelectionField(false)
                            setShowActiveAndInactiveBatteryCount(true)
                        } else {
                            // Custom date option gets selected
                            // As we are showing date selection field, we are updating the date values according to the expected behavior
                            setEndDate(moment(toDate.format("YYYY-MM-DD") + "T23:59:59", "YYYY-MM-DDTHH:mm:ss"))
                            setValue('rent_schedule', rentScheduleOptions[3])
                            setShowDateSelectionField(true)
                            setShowActiveAndInactiveBatteryCount(false)
                        }
                    } else if (172800 < differenceInSeconds && differenceInSeconds <= 604800) {
                        // Difference is more than 48 hours but less or equal to 168 hours/7 days
                        let differenceInDays = parseInt(toDate.diff(fromDate, 'days', true))
                        if (differenceInDays === 6 && toDate.format("DD") === todaysDate) {
                            // Last Week option gets selected
                            setValue('rent_schedule', rentScheduleOptions[2])
                            setShowDateSelectionField(false)
                        } else {
                            // Custom date option gets selected
                            // As we are showing date selection field, we are updating the date values according to the expected behavior
                            setEndDate(moment(toDate.format("YYYY-MM-DD") + "T23:59:59", "YYYY-MM-DDTHH:mm:ss"))
                            setValue('rent_schedule', rentScheduleOptions[3])
                            setShowDateSelectionField(true)
                        }
                        setShowActiveAndInactiveBatteryCount(false)
                    } else {
                        // Custom date option gets selected
                        // As we are showing date selection field, we are updating the date values according to the expected behavior
                        setStartDate(moment(fromDate.format("YYYY-MM-DD") + "T00:00:00", "YYYY-MM-DDTHH:mm:ss"))
                        setEndDate(moment(toDate.format("YYYY-MM-DD") + "T23:59:59", "YYYY-MM-DDTHH:mm:ss"))
                        setValue('rent_schedule', rentScheduleOptions[3])
                        setShowDateSelectionField(true)
                        if (fromDate.format("DD") === todaysDate && toDate.format("DD") === todaysDate) {
                            setShowActiveAndInactiveBatteryCount(true)
                        } else {
                            setShowActiveAndInactiveBatteryCount(false)
                        }
                    }
                }
            }
            if (initialfilterParam.status) {
                if (['active', 'done'].includes(initialfilterParam.status)) {
                    setRentStatus(initialfilterParam.status)
                    setValue('rent_status', initialfilterParam.status)
                } else {
                    setRentStatus('')
                    setValue('rent_status', '')
                    delete filterParamForApiCall['status']
                }
            }
            setInitialfilterParam(null)
        }
    }, [initialfilterParam])

    useEffect(() => {
        if (props.garages && props.garages.length > -1) {
            const garages = props.garages;
            let garagesForSelection = []
            const garageList = garages.map((garage) => {
                let garageName = garage.name
                if (!firstApiCallIsMade && garagesForValidation && garagesForValidation.length > -1) {
                    let filteredData = garagesForValidation.filter(g => g.pk === garage.pk)
                    if (filteredData.length > 0) {
                        garageName = filteredData[0].name
                    }
                }
                if (garageFromUrl) {
                    let garagesFromUrl = garageFromUrl.split(',')
                    if (garagesFromUrl.includes(garage.pk)) {
                        garagesForSelection.push({
                            ...garage,
                            label: garageName,
                            value: garage.pk
                        })
                    }
                }
                return {
                    ...garage,
                    label: garageName,
                    value: garage.pk
                }
            })
            if (garagesForSelection.length > 0) {
                setSelectedGarage(garagesForSelection)
                setGarages(groupSelectedOptions(garagesForSelection, garageList))
            } else {
                setGarages(garageList)
            }
        } else {
            setGarages([])
        }
    }, [props.garages, garagesForValidation])

    useEffect(() => {
        if (props.garagesForValidation && props.garagesForValidation.length > -1) {
            const garages = props.garagesForValidation;
            setGaragesForValidation(garages.map((garage) => {
                return {
                    ...garage
                }
            }))
        }
    }, [props.garagesForValidation])

    useEffect(() => {
        setDateRange(start && end ? `${moment(start).format("DD MMM, YYYY")} - ${moment(end).format("DD MMM, YYYY")}` : '');
    },[start, end])

    const exportData = () => {
        let filter = {}
        if (filterApplied) {
            // TODO: Following can be taken into a function as it has been used in collecting paginated response
            // Sometimes there will not be dates if user doesn't select dates after "Reset Filter" button click
            if (start && end) {
                let from_date = language === 'EN' ? moment(start).format("YYYY-MM-DDTHH:mm:ss") : convertToAnotherLang(moment(start).format("YYYY-MM-DDTHH:mm:ss"))
                let to_date = language === 'EN' ? moment(end).format("YYYY-MM-DDTHH:mm:ss") : convertToAnotherLang(moment(end).format("YYYY-MM-DDTHH:mm:ss"))
                filter['from_date'] = from_date
                filter['to_date'] = to_date
            }
            if (filterParamForApiCall.device_sl) {
                filter['device_sl'] = filterParamForApiCall.device_sl
            }
            if (filterParamForApiCall.dongle_sl) {
                filter['dongle_sl'] = filterParamForApiCall.dongle_sl
            }
            if (filterParamForApiCall.driver_name) {
                filter['driver_name'] = filterParamForApiCall.driver_name
            }
            if (filterParamForApiCall.ev_guid) {
                filter['ev_guid'] = filterParamForApiCall.ev_guid
            }
            if (filterParamForApiCall.garage_guid) {
                filter['garage_guid'] = filterParamForApiCall.garage_guid
            }
            if (filterParamForApiCall.financier_guid) {
                filter['financier_guid'] = filterParamForApiCall.financier_guid
            }
            if (filterParamForApiCall.branch_guid) {
                filter['branch_guid'] = filterParamForApiCall.branch_guid
            }
            if (filterParamForApiCall.status) {
                filter['status'] = filterParamForApiCall.status
            }
            // if (filterParamForApiCall.rent_payment_status) {
            //     filter['rent_payment_status'] = filterParamForApiCall.rent_payment_status
            // }
            GET_RENT_LIST_TO_EXPORT_V1(filter)
        } else {
            GET_RENT_LIST_TO_EXPORT_V1(filter)
        }
    }

    const disableFutureDt = (current) => {
        const today = moment()
        return current.isAfter(today)
    }

    const [previousStart, setPreviousStartDate] = useState(null);
    const [previousEnd, setPreviousEndDate] = useState(null);
    const onDatesChange = ({startDate, endDate}) => {
        if (startDate && endDate){
            startDate = moment(startDate)
            endDate = moment(endDate)

            let sameDateIsSelected = false
            if (endDate.isSame(startDate)) {
                sameDateIsSelected = true
            }

            startDate.set({ 'hour': 0, 'minute': 0, 'second': 0 })

            if (!sameDateIsSelected && moment.duration(endDate.diff(startDate)).asHours() < 24) {
                endDate.set({ 'hour': 0, 'minute': 0, 'second': 0 })
            }else{
                endDate.set({ 'hour': 23, 'minute': 59, 'second': 59 })
            }
        }

        if (previousEnd && previousEnd.isSame(endDate)) {
            setPreviousStartDate(endDate)
            setPreviousEndDate(endDate)

            setStartDate(endDate)
            setEndDate(endDate)
        } else {
            setPreviousStartDate(startDate)
            setPreviousEndDate(endDate)

            setStartDate(startDate)
            setEndDate(endDate)
        }
    }

    const handleFocusChange = (input) => {
        if (!input) {
            setFocusedInput('startDate')
        } else {
            setFocusedInput(input)
        }
    }

    const popover = (
        <Popover id="popover-basic" bsPrefix={'custom-popover'}>
            <Popover.Content bsPrefix={'custom-popover'}>
                <DayPickerRangeController
                    {...props}
                    startDate={start}
                    endDate={end}
                    numberOfMonths={2}
                    minimumNights={0}
                    isOutsideRange={disableFutureDt}
                    onDatesChange={onDatesChange}
                    focusedInput={focusedInput}
                    onFocusChange={(focusedInput) => handleFocusChange(focusedInput)}
                    keepOpenOnDateSelect={true}
                />
            </Popover.Content>
        </Popover>
    )

    const renderDriverDetailsModal = () => {
        return <>
            <GenericModal
                size={'md'}
                showModalHeader={true}
                footer={false}
                hideModal={hideDriverDetailsModal}
                centered={false}
                modal={showDriverDetailsModal}
                title={<>{driverName}</>}>
                <div className={'row'}>
                    {props.driverDetailsCollectionInProgress?
                        <>
                            <div className={'col-md-4'}></div>
                            <div className={'col-md-4'}>
                                <LoadingSpinner
                                    loadingSubText={language === 'EN'? 'Collecting driver details..': 'চালকের বিস্তারিত তথ্য প্রস্তুত হচ্ছে..'}
                                    language={language}/>
                            </div>
                            <div className={'col-md-4'}></div>
                        </>:<>
                            <div className={'col-md-6'}>
                                <Avatar src={driverNIDImageUrl} variant={'square'}
                                        sx={{ width: '100%', height: '100%' }} className={'text-center'}>
                                    <h4>NID document has not been uploaded</h4>
                                </Avatar>
                            </div>
                            <div className={'col-md-6'}>
                                <ListGroup variant={"flush"}>
                                    {driverUserID? <>
                                        <ListGroup.Item className={"border-bottom-0"}>
                                            <small className={'text-muted'}>User ID</small><br/>
                                            <h4><strong>{driverUserID}</strong></h4>
                                        </ListGroup.Item>
                                    </>:''}
                                    <ListGroup.Item className={"border-bottom-0"}>
                                        <small className={'text-muted'}>Last EV</small><br/>
                                        <h4><strong>{driverLastEvID? '#'+ driverLastEvID + ' - Easy Bike':'N/A'}</strong></h4>
                                    </ListGroup.Item>
                                    <ListGroup.Item className={"border-bottom-0"}>
                                        <small className={'text-muted'}>NID</small><br/>
                                        <h4><strong>{driverNID}</strong></h4>
                                    </ListGroup.Item>
                                    <ListGroup.Item className={"border-bottom-0"}>
                                        <small className={'text-muted'}>Mobile</small><br/>
                                        <h4><strong>{driverMobileNumber}</strong></h4>
                                    </ListGroup.Item>
                                    <ListGroup.Item className={"border-bottom-0"}>
                                        <small className={'text-muted'}>Address</small><br/>
                                        <h4><strong>{driverAddress}</strong></h4>
                                    </ListGroup.Item>
                                    <ListGroup.Item className={"border-bottom-0"}>
                                        <small className={'text-muted'}>Garage</small><br/>
                                        <h4><strong>{driverGarageName && driverGarageAddress? driverGarageName + ', ' + driverGarageAddress:driverGarageName? driverGarageName:'N/A'}</strong></h4>
                                    </ListGroup.Item>
                                </ListGroup>
                            </div>
                        </>}
                </div>
            </GenericModal>
        </>
    }

    const showInactiveBatteries = () => {
        setShowInactiveBatteryModal(true)
    }

    const hideInactiveBatteries = () => {
        setShowInactiveBatteryModal(false)
    }

    const renderInactiveBatteriesModal = () => {
        return <>
            <GenericModal
                size={'lg'}
                showModalHeader={true}
                footer={false}
                hideModal={hideInactiveBatteries}
                centered={false}
                modal={showInactiveBatteryModal}
                title={<>Inactive Batteries</>}>
                <InactiveBatteries fromDate={start} toDate={end} garageGuid={selectedGarage ? formulateApiRequestDataFromSelectedItems(selectedGarage) : null} organizationGuid={selectedFinancier ? formulateApiRequestDataFromSelectedItems(selectedFinancier) : null} branchGuid={branch ? formulateApiRequestDataFromSelectedItems(branch) : null} totalInactiveBatteries={totalInactiveEV}
                                   hideModalEvent={hideInactiveBatteries}/>
            </GenericModal>
        </>
    }

    return (
        <div>
            <ContentWrapper showCardHeader={false} showBackButton={false} isLoading={props.isLoading}
                            serverError={false} permissionDenied={false} pageTitle={pageTitle}
                            statusCode={props.statusCode}>
                <div className={'row'}>
                    <div className={'col-md-12'}>
                        <Toolbar>
                            <Toolbar.Title>
                                <h1><b>{pageTitle}</b></h1>
                            </Toolbar.Title>
                            <Toolbar.ToolbarContainer>
                                <Toolbar.ToolbarContainer.Items>
                                    <IconButton onClick={filterToggle} classes={classes}>
                                        <FilterListIcon color="disabled" fontSize="large"/>
                                    </IconButton>
                                    <IconButton classes={classes} onClick={exportData}
                                                disabled={props.listLoading || props.isLoading || props.iconLoading}>
                                        {props.iconLoading? <Spinner animation={"grow"} variant={'warning'} size={"lg"}/> :
                                            <img src={require('../../../../utils/asset/download-Button.svg')}
                                                 alt="Download"/>}
                                    </IconButton>
                                    {/* {checkAuthorization(authorization, driverServiceName, checkOutFeatureName, [createActionName])? <>
                                        <Button variant="warning" type="button" size={'sm'} style={{
                                            backgroundColor: '#F18D00 !important',
                                        }} onClick={gotToCheckOutDriver} classes={classes} >
                                            <i className="fa fa-toggle-on"/>{language === 'EN'? 'Activate battery':'ব্যাটারী ভাড়া দিন'}
                                        </Button>
                                    </>:null} */}
                                </Toolbar.ToolbarContainer.Items>
                            </Toolbar.ToolbarContainer>
                        </Toolbar>
                    </div>
                </div>
                <hr/>
                {showFilter?
                    <>
                        <div className={'row'}>
                            <div className={'col-md-12'}>
                                <Form onSubmit={handleSubmit(filterTheList)}>
                                    <div className={'row g-3'}>
                                        <div className={'col-md-2'}>
                                            <Form.Group>
                                                <div>
                                                    <Form.Label>{language === 'EN'? 'Smart Battery ID':'স্মার্ট ব্যাটারী আইডি'}</Form.Label>
                                                </div>
                                                <SolInput
                                                    type={"text"}
                                                    name={"smart_battery_id"}
                                                    placeholder={language === "EN"? "Type Smart Battery ID...":"স্মার্ট ব্যাটারী আইডি লিখুন..."}
                                                    autoComplete={"off"}
                                                    ref={register({
                                                        validate: {
                                                            isNumber: value => (/^(0|[1-9][0-9]*)$/i.test(value) || !value) || invalidSerial
                                                        }
                                                    })}
                                                />
                                                {errors.smart_battery_id && <div className="text-danger">
                                                    <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.smart_battery_id.message}</div>}
                                            </Form.Group>
                                        </div>
                                        <div className={'col-md-2'}>
                                            <Form.Group>
                                                <div>
                                                    <Form.Label>{language === 'EN'? 'SOLdongle ID':'ডঙ্গল আইডি'}</Form.Label>
                                                </div>
                                                <SolInput
                                                    type={"text"}
                                                    name={"dongle_id"}
                                                    placeholder={language === "EN"? "Type SOLdongle ID...":"ডঙ্গল আইডি লিখুন..."}
                                                    autoComplete={"off"}
                                                    ref={register({
                                                        validate: {
                                                            isNumber: value => (/^(0|[1-9][0-9]*)$/i.test(value) || !value) || invalidSerial,
                                                            checkLength: value => (value.toString().length === 8 || !value) || invalidSerial,
                                                            startsWithCorrectCharacter: value => (value.startsWith('44') || !value) || invalidSerial
                                                        }
                                                    })}
                                                />
                                                {errors.dongle_id && <div className="text-danger">
                                                    <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.dongle_id.message}</div>}
                                            </Form.Group>
                                        </div>
                                        <div className={'col-md-2'}>
                                            <Form.Group>
                                                <div>
                                                    <Form.Label>{language === 'EN'? 'Driver':'চালক'}</Form.Label>
                                                </div>
                                                <SolInput
                                                    type={"text"}
                                                    name={"driver"}
                                                    placeholder={"Type Driver Name..."}
                                                    autoComplete={"off"}
                                                    ref={register({
                                                        validate: {
                                                            isValidName: value => (/^[a-zA-Z-'. ]+$/.test(value) || !value) || "Invalid Driver Name!"
                                                        }
                                                    })}
                                                />
                                                {errors.driver && <div className="text-danger">
                                                    <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.driver.message}</div>}
                                            </Form.Group>
                                        </div>
                                        <div className={'col-md-2'}>
                                            <Form.Group>
                                                <div>
                                                    <Form.Label>Ev</Form.Label>
                                                </div>
                                                <Controller
                                                    control={control}
                                                    name={"ev"}
                                                    render={( { onChange, onBlur, value, name, ref },
                                                              { invalid, isTouched, isDirty }) => (
                                                        <Select
                                                            placeholder={'Select Ev'}
                                                            classNamePrefix="react-select-sol-style"
                                                            isDisabled={props.collectingEvs}
                                                            isLoading={props.collectingEvs}
                                                            maxMenuHeight={200}
                                                            value={value}
                                                            isClearable={true}
                                                            control={control}
                                                            inputRef={ref}
                                                            options={evs}
                                                            isSearchable={true}
                                                            styles={customStylesForSingleSelectField}
                                                            onChange={(selected, {action}) => {
                                                                if (action === "clear") {
                                                                    setSelectedEv('')
                                                                }
                                                                onChange(selected)
                                                                if (selected) {
                                                                    setSelectedEv(selected)
                                                                }
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </Form.Group>
                                        </div>
                                        <div className={'col-md-2'}>
                                            <Form.Group>
                                                <div>
                                                    <Form.Label>Garage</Form.Label>
                                                </div>
                                                <Select
                                                    placeholder={'Select Garage'}
                                                    name={'garage'} // This name is used to show 'n garage is selected' text
                                                    value={selectedGarage}
                                                    classNamePrefix="react-select-sol-style"
                                                    isDisabled={props.garageCollectionInProgress}
                                                    isLoading={props.garageCollectionInProgress}
                                                    maxMenuHeight={200}
                                                    isClearable={true}
                                                    options={garages}
                                                    isSearchable={true}
                                                    isMulti={true}
                                                    hideSelectedOptions={false}
                                                    styles={customStylesForMultiSelectField}
                                                    components={{ ValueContainer, Option }}
                                                    onChange={(selected, {action}) => {
                                                        if (action === "clear") {
                                                            setSelectedGarage('')
                                                        }
                                                        if (selected) {
                                                            setSelectedGarage(selected)
                                                            setGarages(groupSelectedOptions(selected, garages))
                                                        }
                                                    }}
                                                />
                                            </Form.Group>
                                        </div>
                                        {props.isSolshareUser ? <div className={'col-md-2'}>
                                            <Form.Group>
                                                <div>
                                                    <Form.Label>Financier</Form.Label>
                                                </div>
                                                <Select
                                                    placeholder={'Select Financier'}
                                                    name={"financier"} // This name is used to show 'n item selected' text
                                                    value={selectedFinancier}
                                                    classNamePrefix="react-select-sol-style"
                                                    isDisabled={props.orgListLoading}
                                                    isLoading={props.orgListLoading}
                                                    maxMenuHeight={200}
                                                    isClearable={true}
                                                    options={financiers}
                                                    isSearchable={true}
                                                    isMulti={true}
                                                    hideSelectedOptions={false}
                                                    styles={customStylesForMultiSelectField}
                                                    components={{ ValueContainer, Option }}
                                                    onChange={(selected, { action }) => {
                                                        if (action === "clear") {
                                                            setSelectedFinancier('')
                                                            GET_BRANCHES_LIST()
                                                        }
                                                        setBranch( '')
                                                        if (selected) {
                                                            setSelectedFinancier(selected)
                                                            setFinanciers(groupSelectedOptions(selected, financiers))
                                                            GET_BRANCHES_LIST({'organization_guid': formulateApiRequestDataFromSelectedItems(selected)})
                                                        }
                                                    }}
                                                />
                                            </Form.Group>
                                        </div>: null}
                                    </div>
                                    <div className={'row g-3'}>
                                        <div className={'col-md-2'}>
                                            <Form.Group>
                                                <div>
                                                    <Form.Label>Branch</Form.Label>
                                                </div>
                                                <Select
                                                    placeholder={'Select Branch'}
                                                    name={"branch"} // This name is used to show 'n item selected' text
                                                    value={branch}
                                                    classNamePrefix="react-select-sol-style"
                                                    isDisabled={props.collectingBranches}
                                                    isLoading={props.collectingBranches}
                                                    maxMenuHeight={200}
                                                    isClearable={true}
                                                    options={branches}
                                                    isSearchable={true}
                                                    isMulti={true}
                                                    hideSelectedOptions={false}
                                                    styles={customStylesForMultiSelectField}
                                                    components={{ ValueContainer, Option }}
                                                    noOptionsMessage={() => "No branches available"}
                                                    onChange={(selected, { action }) => {
                                                        if (action === "clear") {
                                                            setBranch('')
                                                        }
                                                        if (selected) {
                                                            setBranch(selected)
                                                            setBranches(groupSelectedOptions(selected, branches))
                                                        }
                                                    }}
                                                />
                                            </Form.Group>
                                        </div>
                                        <div className={'col-md-2'}>
                                            <Form.Group>
                                                <div>
                                                    <Form.Label>{language === 'EN'? 'Rent Schedule':'ভাড়ার শিডিউল'}</Form.Label>
                                                </div>
                                                <div style={showDateSelectionField? {}:{'display': 'none'}}>
                                                    <OverlayTrigger rootClose trigger="click" placement="bottom-start"
                                                                    overlay={popover}>
                                                        <SolInput
                                                            name={'dateRangePickerInput'}
                                                            readOnly={true}
                                                            value={dateRange}
                                                            placeholder={language === 'EN'? 'Select a date range':'তারিখের সীমা বাছাই করুন'}
                                                        />
                                                    </OverlayTrigger>
                                                </div>
                                                <div style={showDateSelectionField? {'display': 'none'}:{}}>
                                                    <Controller
                                                        control={control}
                                                        name={"rent_schedule"}
                                                        render={( { onChange, onBlur, value, name, ref },
                                                                  { invalid, isTouched, isDirty }) => (
                                                            <Select
                                                                placeholder={language === 'EN'? 'Select Schedule Type':'ভাড়ার শিডিউলের ধরন বাছাই করুন'}
                                                                classNamePrefix="react-select-sol-style"
                                                                maxMenuHeight={200}
                                                                value={value}
                                                                isClearable={true}
                                                                control={control}
                                                                inputRef={ref}
                                                                options={rentScheduleOptions}
                                                                isSearchable={true}
                                                                styles={customStylesForSingleSelectField}
                                                                onChange={(selected, {action}) => {
                                                                    if (action === "clear") {
                                                                        setValue('rent_schedule', '')
                                                                    }
                                                                    onChange(selected)
                                                                    if (selected) {
                                                                        setValue('rent_schedule', selected)
                                                                        if (selected.value === 'last_24') {
                                                                            setShowDateSelectionField(false)
                                                                            collectLast24HoursData()
                                                                        } else if (selected.value === 'last_two_days') {
                                                                            setShowDateSelectionField(false)
                                                                            collectLastTwoDaysData(false)
                                                                        } else if (selected.value === 'last_week') {
                                                                            setShowDateSelectionField(false)
                                                                            collectLastSevenDaysData()
                                                                        } else if (selected.value === 'custom_date') {
                                                                            setShowDateSelectionField(true)
                                                                            setStartDate(null)
                                                                            setEndDate(null)
                                                                        }
                                                                    }
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                </div>
                                                {errors.rent_schedule && <div className="text-danger">
                                                    <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.rent_schedule.message}</div>}
                                            </Form.Group>
                                        </div>
                                        <div className={'col-md-2'}>
                                            <Form.Group>
                                                <div>
                                                    <Form.Label>{language === 'EN'? 'Rent Status':'ভাড়ার অবস্থা'}</Form.Label>
                                                </div>
                                                <div className="form-check form-check-inline mt-2">
                                                    <SolRadioInput
                                                        {...register("rent_status")}
                                                        id="active"
                                                        value={`active`}
                                                        handleChange={(event) => handleChangeRentStatus(event)}
                                                        checked={rentStatus === "active"}
                                                        htmlFor="active"
                                                        labelName="Active"
                                                    />
                                                </div>
                                                <div className="form-check form-check-inline mt-2">
                                                    <SolRadioInput
                                                        {...register("rent_status")}
                                                        id="done"
                                                        value={`done`}
                                                        handleChange={(event) => handleChangeRentStatus(event)}
                                                        checked={rentStatus === "done"}
                                                        htmlFor="done"
                                                        labelName="Complete"
                                                    />
                                                </div>
                                            </Form.Group>
                                        </div>
                                    </div>
                                    <div className={'row'}>
                                        <div className={"col-md-12"}>
                                            <Button variant="warning" size={'sm'} type="submit"
                                                    disabled={props.listLoading}>
                                                {language === 'EN'? 'Apply filter':'ফিল্টার করুন'}
                                            </Button>
                                            <Button variant="outline-dark" size={'sm'} disabled={props.listLoading}
                                                    onClick={()=> {resetFilter()}} className={'ml-3'}>
                                                {language === 'EN'? 'Reset filter':'ফিল্টার করার তথ্য পরিষ্কার করুন'}
                                            </Button>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </div>
                        <hr/></>:null}
                <div className={isTabletOrMobile? 'row':'row ml-1 mt-7'}>
                    {/* We are using Stack for desktop view to make design aligned with Figma */}
                    {/* But as at mobile view Stack isn't working as expected, we have used 'div' */}
                    {/* - Noor Reza, 15th October, 2:38 PM */}
                    {!isTabletOrMobile? <>
                        <Stack
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                            spacing={10}
                        >
                            <Stack
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-end"
                                spacing={1}
                            >
                                <Avatar alt={'TOTAL'} src={require('../../../../utils/asset/LIBAT_deployed.svg')} variant={'square'}/>
                                <Stack direction="column" justifyContent="flex-end" alignItems="flex-start" spacing={0}>
                                    <><h5><strong>{totalDeployedEV}</strong></h5></>
                                    <><small>Batteries Deployed</small></>
                                </Stack>
                            </Stack>
                            {showActiveAndInactiveBatteryCount? <>
                                <Stack
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="flex-end"
                                    spacing={1}
                                >
                                    <Avatar alt={'ACTIVE'} src={require('../../../../utils/asset/LIBAT_rented.svg')} variant={'square'}/>
                                    <Stack direction="column" justifyContent="flex-end" alignItems="flex-start" spacing={0}>
                                        <><h5><strong>{totalActiveEV}</strong></h5></>
                                        <><small>Batteries Rented</small></>
                                    </Stack>
                                </Stack>
                                <Stack
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="flex-end"
                                    spacing={1}
                                >
                                    <Avatar children={ <DoNotDisturbAltRoundedIcon/> } variant={'square'} style={inactiveBatteryIconStyle}/>
                                    <Stack direction="column" justifyContent="flex-end" alignItems="flex-start" spacing={0}>
                                        <>{totalInactiveEV? <>
                                            <a onClick={showInactiveBatteries}><h5><strong>{totalInactiveEV}</strong></h5></a>
                                        </>:<><h5><strong>{totalInactiveEV}</strong></h5></>}</>
                                        <><small>Inactive Batteries</small></>
                                    </Stack>
                                </Stack>
                            </>:null}
                        </Stack>
                    </>:<>
                        <div className={showActiveAndInactiveBatteryCount? 'col-md-4':'col-md-12'}>
                            <Stack
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-end"
                                spacing={1}
                            >
                                <Avatar alt={'TOTAL'} src={require('../../../../utils/asset/LIBAT_deployed.svg')} variant={'square'}/>
                                <Stack direction="column" justifyContent="flex-end" alignItems="flex-start" spacing={0}>
                                    <><h5><strong>{totalDeployedEV}</strong></h5></>
                                    <><small>Batteries Deployed</small></>
                                </Stack>
                            </Stack>
                        </div>
                        {showActiveAndInactiveBatteryCount? <>
                            <div className={'col-md-4 mt-5'}>
                                <Stack
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="flex-end"
                                    spacing={1}
                                >
                                    <Avatar alt={'ACTIVE'} src={require('../../../../utils/asset/LIBAT_rented.svg')} variant={'square'}/>
                                    <Stack direction="column" justifyContent="flex-end" alignItems="flex-start" spacing={0}>
                                        <><h5><strong>{totalActiveEV}</strong></h5></>
                                        <><small>Batteries Rented</small></>
                                    </Stack>
                                </Stack>
                            </div>
                            <div className={'col-md-4 mt-5'}>
                                <Stack
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="flex-end"
                                    spacing={1}
                                >
                                    <Avatar children={ <DoNotDisturbAltRoundedIcon/> } variant={'square'} style={inactiveBatteryIconStyle}/>
                                    <Stack direction="column" justifyContent="flex-end" alignItems="flex-start" spacing={0}>
                                        <>{totalInactiveEV? <>
                                            <a onClick={showInactiveBatteries}><h5><strong>{totalInactiveEV}</strong></h5></a>
                                        </>:<><h5><strong>{totalInactiveEV}</strong></h5></>}</>
                                        <><small>Inactive Batteries</small></>
                                    </Stack>
                                </Stack>
                            </div>
                        </>:null}
                    </>}
                </div>
                <div className={'row'}>
                    <div className={'col-md-12'}>
                        <DataTableContainer>
                            {!props.listLoading? <DataTable
                                language={language}
                                noDataAvailableMessageInBangla={'কোন ভাড়া নেই'}
                                noDataAvailableMessageInEnglish={'No rent logs are available'}
                                columns={rentListColumns}
                                data={rentList}
                                showToolbar={false}
                                asyncPagination={true}
                                count={props.rentList?.count}
                                itemsPerPage={props.rentList?.page_size}
                                onChangePage={onChangePage}
                                page={page}
                                filterApplied={filterApplied}
                            /> : <LoadingSpinner loadingSubText={language === 'EN'? 'Collecting battery rent logs ..': 'ব্যাটারী ভাড়া দেওয়ার লগ প্রস্তুত হচ্ছে'} language={language}/>}
                        </DataTableContainer>
                    </div>
                </div>

                {renderDriverDetailsModal()}
                {renderInactiveBatteriesModal()}
            </ContentWrapper>

            <style jsx>{`
              .filter-animation {
                animation-name: breath-in;
                animation-duration: 40ms;
              }

              @keyframes breath-in {
                0% {
                  height: 0;
                  opacity: 0;
                }
                10% {
                  height: 10px;
                  opacity: 0.10;
                }
                20% {
                  height: 20px;
                  opacity: 0.20;
                }
                30% {
                  height: 30px;
                  opacity: 0.30;
                }
                40% {
                  height: 40px;
                  opacity: 0.40;
                }
                50% {
                  height: 50px;
                  opacity: 0.50;
                }
                60% {
                  height: 60px;
                  opacity: 0.60;
                }
                70% {
                  height: 70px;
                  opacity: 0.70;
                }
                80% {
                  height: 80px;
                  opacity: 0.80;
                }
                90% {
                  height: 90px;
                  opacity: 0.90;
                }
                100% {
                  height: auto;
                  opacity: 1.00;
                }
              }

              //@keyframes breath-out {
              //    0% { height: auto;  opacity: 1.00; }
              //    10% { height: 90px;  opacity: 0.90; }
              //    20% { height: 80px;  opacity: 0.80; }
              //    30% { height: 70px;  opacity: 0.70; }
              //    40% { height: 60px;  opacity: 0.60; }
              //    50% { height: 50px;  opacity: 0.50; }
              //    60% { height: 40px;  opacity: 0.40; }
              //    70% { height: 30px;  opacity: 0.30; }
              //    80% { height: 20px;  opacity: 0.20; }
              //    90% { height: 10px;  opacity: 0.10; }
              //    100% { height: 0; opacity: 0;  }  
              //}


            `}</style>
        </div>
    );
};

RentLog.propTypes = {};

const mapStateToProps = (state) => {
    return {
        language: state.auth.language,
        authorization: state.auth.authorizations,
        isLoading: state.rentLogReducer.isLoading,
        iconLoading: state.rentLogReducer.iconLoading,
        isSolshareUser: state.auth.is_solshare_user,
        rentList: state.rentLogReducer.rentList,
        errorMessageForRentListCollection: state.rentLogReducer.errorMessageForRentListCollection,
        rentListForExport: state.rentLogReducer.rentListForExport,
        errorMessageForRentListExport: state.rentLogReducer.errorMessageForRentListExport,
        listLoading: state.rentLogReducer.listLoading,
        successMessageForCheckIn: state.rentLogReducer.successMessageForCheckIn,
        errorMessageForCheckIn: state.rentLogReducer.errorMessageForCheckIn,
        garages: state.rentLogReducer.garages,
        garageCollectionInProgress: state.rentLogReducer.garageCollectionInProgress,
        driverDetails: state.rentLogReducer.driverDetails,
        driverDetailsCollectionInProgress: state.rentLogReducer.driverDetailsCollectionInProgress,
        garagesForValidation: state.commonReducer.garages,
        branches: state.commonReducer.branches,
        collectingBranches: state.commonReducer.collectingBranches,
        organisations: state.commonReducer.organisations,
        orgListLoading: state.commonReducer.orgListLoading,
        orgListCollectionError: state.commonReducer.orgListCollectionError,
        evs: state.rentLogReducer.evs,
        collectingEvs: state.rentLogReducer.collectingEvs,
        errorMessageForEvListCollection: state.rentLogReducer.errorMessageForEvListCollection
    }
}

export default connect(mapStateToProps, {...actions, ...commonActions})(withRoles(RentLog));