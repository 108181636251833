import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import ContentWrapper from "../../../../components/contentWrapper/contentWrapper";
import {Toolbar} from "../../../smartDongles/views/components";
import {SolInput} from "../../../../components/SolStyledComponents/components";
import {LoadingSpinner} from '../../../../components/LoadingSpinnerForDataTable';
import DataTable from "../../../../components/dataTable/DataTable";
import {connect} from "react-redux";
import {actions} from "../../actions";
import {actions as commonActions} from "../../../commonReduxActions/actions";
import {Badge, Button, Spinner} from "react-bootstrap";
import {useSubheader} from "../../../../../_metronic/layout";
import {useLocation} from 'react-router';
import moment from "moment";
import {Form} from "react-bootstrap";
import Select from 'react-select';
import {Controller, useForm} from "react-hook-form";
import FwUpdateHistoriesTable from "../../../../components/fwUpdateHistory/FwUpdateHistoriesTable"
import {customStylesForMultiSelectField} from "../../../../utils/reactSelectRelated/customStylesForMultiSelect";
import {ValueContainer} from "../../../../utils/reactSelectRelated/showCustomizedValueLabelForMultiSelect";
import {Option} from "../../../../utils/reactSelectRelated/showOptionsWithCheckbox";
import {groupSelectedOptions} from "../../../../utils/reactSelectRelated/groupSelectedOptionsForMultiSelect";
import {customStylesForSingleSelectField} from "../../../../utils/reactSelectRelated/customStylesForSingleSelect";
import {formulateApiRequestDataFromSelectedItems} from "../../../../utils/multiSelectValueFormation/formatForApiRequest";
import Box from '@mui/material/Box';
import {showNotifications} from "../../../../utils/notification";
import {otaReducer} from "../../reducer";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import {DataTableContainerForDongleList, requestCycle} from "../../utils";
import Skeleton from "@mui/material/Skeleton";
import {makeStyles} from "@material-ui/styles";
import DateTimeFormat from "../../../../utils/dateFormat/DateTimeFormat";
import {GenericModal} from "../../../../components";
import {DataTableContainer} from "../../../rentLog/utils";
import Typography from "@mui/material/Typography";
import {SolTagInput} from "../../../../components/solTagInput/SolTagInput"
import Alert from "@mui/material/Alert";
import {toast} from "react-toastify";


const AssignDongles = props => {
    const {register, errors, control, clearErrors, handleSubmit, reset, setValue, setError, getValues} = useForm()
    const [authorization, setAuthorization] = useState(null)
    const [language, setLanguage] = useState(null)
    const subHeader = useSubheader()
    const location = useLocation()

    // Page related
    const [mainFirmware, setMainFirmware] = useState('')
    const [lastVisitedPage, setLastVisitedPage] = useState('')
    const [compatibleHwVersions, setCompatibleHwVersions] = useState([])

    // Filter related
    const [financiers, setFinanciers] = useState([])
    const [financier, setFinancier] = useState("")
    const [garages, setGarages] = useState([])
    const [garage, setGarage] = useState("")
    const [installedFirmwares, setInstalledFirmwares] = useState([])
    const [selectedFirmware, setSelectedFirmware] = useState('')

    // Tab styling and selection related
    const useStyles = makeStyles((theme) => ({
        tab: {
            '&:hover': {
                color: '#F18D00'
            },
            '&:focus': {
                color: '#F18D00'
            },
            '&.Mui-selected': {
                color: '#F18D00'
            },
            textTransform: 'none',
            ...theme.typography.h6
        },
        indicator: {
            backgroundColor: '#F18D00'
        }
    }));
    const classes = useStyles();
    const [tabValue, setTabValue] = useState(0)
    const TabPanel = ({...props}) => {
        const { children, value, index, ...other } = props;

        return (
            <div role="tabpanel" hidden={value !== index} id={`tabpanel-${index}`} aria-labelledby={`tab-${index}`}
                 {...other}>
                {value === index && (
                    <Box p={2}>{children}</Box>
                )}
            </div>
        );
    }
    const apiKeysAndTabValueMap = {
        0: "eligible_dongles",
        1: "active_dongles",
        2: "allowed_dongles"
    }
    const [eligibleDongleListLabel, setEligibleDongleListLabel] = useState('Eligible SOLdongles')
    const [activeDongleListLabel, setActiveDongleListLabel] = useState('Active SOLdongles')
    const [allowedDongleListLabel, setAllowedDongleListLabel] = useState('Allowed SOLdongles')

    // Dongle list related
    const [filterParam, setFilterParam] = useState({})
    const [showOldFwColumn, setShowOldFwColumn] = useState(false)
    const [eligibleDongles, setEligibleDongles] = useState(undefined)
    const [activeDongles, setActiveDongles] = useState(undefined)
    const [allowedDongles, setAllowedDongles] = useState(undefined)
    const commonColumns = [
        {
            field: 'serial_number',
            title: 'SOLdongle',
            emptyValue: ()=>{
                return "-"
            },
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            render: (rowData)=>{
                return   <>
                    <h6><strong>{rowData.serial_number}</strong></h6>
                    <span className={"text-nowrap"}>HW v{rowData.hardware_version}</span>
                </>
            }
        },
        {
            field: 'latest_event_details.device_serial_number',
            title: 'Smart Battery',
            emptyValue: ()=>{
                return "-"
            },
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            render: (rowData) => {
                return <>
                    <h6><strong>{rowData.latest_event_details.device_serial_number}</strong></h6>
                </>
            }
        },
        {
            field: 'firmware_version',
            title: 'Current F.W.',
            emptyValue: ()=>{
                return "-"
            },
            cellStyle: {
                textAlign: 'center'
            },
            headerStyle: {
                textAlign: 'center'
            },
            render: (rowData)=>{
                return   <>
                    <span className={"text-nowrap"}>{rowData.firmware_version}</span>
                </>
            }
        }
    ]
    const dongleListColumns = showOldFwColumn? [
            ...commonColumns,
        {
            field: 'old_firmware_version',
            title: 'OLD F.W.',
            emptyValue: ()=> {
                return "-"
            },
            cellStyle: {
                textAlign: 'center'
            },
            headerStyle: {
                textAlign: 'center'
            },
            render: (rowData)=>{
                return   <>
                    <span className={"text-nowrap"}>{rowData.old_firmware_version}</span>
                </>
            }
        }
    ]:[...commonColumns]

    // Dongle to FW assignment related
    const [selectedDongles, setSelectedDongles] = useState([])
    const [showError, setShowError] = useState(false)

    // "See Update History" action related
    const [showFwUpdateHistoryModal, setShowFwUpdateHistoryModal] = useState(false)
    const [dongleForModal, setDongleForModal] = useState('')
    const [histories, setHistories] = useState(undefined)


    const {
        GET_HARDWARE_LIST,
        GET_ORGANISATIONS,
        GET_GARAGE_LIST,
        GET_FIRMWARE_LIST,
        GET_FIRMWARE_UPDATE_LIST,
        COLLECT_DONGLES_BASED_ON_OTA_ELIGIBILITY,
        ASSIGN_FW_TO_DONGLES,
        RETURN_TO_INITIAL_STATES_FOR_COMMON_REDUCER,
        RETURN_TO_INITIAL_STATE_OF_ASSIGN_DONGLES_COMPONENT
    } = props

    useEffect(()=>{
        /**
         * Initializes component state and session data, retrieves various lists, and resets state on component unmount.
         *
         * This `useEffect` hook runs once on initial render to:
         * - Set authorization and language state values from `props`.
         * - Set the breadcrumb component to `null` in `subHeader`.
         * - Retrieve and set firmware version and last visited page data from either the `location` object or `sessionStorage`.
         * - Retrieve organizations if the user is a `Solshare` user and fetch hardware, garage, and firmware lists.
         *
         * Additionally, the hook provides a cleanup function to reset states in certain reducers on component unmount.
         *
         * @param {Object} props - The component properties.
         * @param {string} props.authorization - Authorization token or data for the component.
         * @param {string} props.language - Language setting for the component.
         * @param {boolean} props.isSolshareUser - Indicates if the user is a `Solshare` user, triggering additional data retrieval.
         *
         * Side Effects:
         * - Calls `GET_ORGANISATIONS` if `props.isSolshareUser` is `true`.
         * - Calls `GET_HARDWARE_LIST`, `GET_GARAGE_LIST`, and `GET_FIRMWARE_LIST` functions to retrieve data lists.
         * - Sets session storage for `fwVersion` and `page` if data is available from `location`.
         *
         * Cleanup:
         * - Calls `RETURN_TO_INITIAL_STATES_FOR_COMMON_REDUCER` and `RETURN_TO_INITIAL_STATE_OF_ASSIGN_DONGLES_COMPONENT`
         *   to reset states in associated reducers when the component is unmounted.
         *
         * @returns {Function} Cleanup function that resets specific states on component unmount.
         */
        setAuthorization(props.authorization)
        setLanguage(props.language)
        subHeader.setBreadCrumbComponent(null)
        if (location.fwData) {
            let fw = location.fwData.version
            setMainFirmware(fw)
            sessionStorage.setItem("fwVersion", fw)
            let page = location.page
            setLastVisitedPage(page)
            sessionStorage.setItem("page", page)
        } else if (sessionStorage.getItem("fwVersion")) {
            setMainFirmware(sessionStorage.getItem("fwVersion"))
            setLastVisitedPage(sessionStorage.getItem("page"))
        }
        if (props.isSolshareUser) {
            GET_ORGANISATIONS()
        }
        GET_HARDWARE_LIST()
        GET_GARAGE_LIST()
        GET_FIRMWARE_LIST()
        return ()=>{
            RETURN_TO_INITIAL_STATES_FOR_COMMON_REDUCER()
            RETURN_TO_INITIAL_STATE_OF_ASSIGN_DONGLES_COMPONENT()
        }
    },[])

    useEffect(()=> {
        if (mainFirmware) {
            let initialParam = {"new_firmware_version": mainFirmware,
                "tab_identifier": apiKeysAndTabValueMap[0]}
            setFilterParam(initialParam)
            COLLECT_DONGLES_BASED_ON_OTA_ELIGIBILITY(initialParam)
        }
    },[mainFirmware])

    useEffect(() => {
        /**
         * Load options for the "Hardware Compatibility" field from the response of the "GET_HARDWARE_LIST" API
         * The effect runs on changes of the API response which is actually property "hardwareList" from the "otaReducer"
         */
        if (props.hardwareList) {
            setCompatibleHwVersions(props.hardwareList.map((hardware) => {
                let version = hardware.version
                return {
                    ...hardware,
                    label: version,
                    value: version
                }
            }))
        }
    }, [props.hardwareList])

    useEffect(() => {
        /**
         * Displays an error notification if an error occurs during the "GET_HARDWARE_LIST" API call.
         *
         * This effect runs whenever there is a change in the "hardwareListCollectionError" property from the "otaReducer".
         * If "hardwareListCollectionError" has a value, it triggers `showNotifications` with an 'error' type and
         * the error message to inform the user of any issues from the "GET_HARDWARE_LIST" API response.
         *
         * @param {string} props.hardwareListCollectionError - Error message from the "GET_HARDWARE_LIST" API, if any.
         */
        if (props.hardwareListCollectionError) {
            showNotifications('error', props.hardwareListCollectionError)
        }
    }, [props.hardwareListCollectionError])

    // ----- Begin: Dongle list filtering process related ---- //
    useEffect(() => {
        if (props.organisations) {
            const organisations = props.organisations
            setFinanciers(organisations.map((org) => {
                return {
                    ...org,
                    label: org.name,
                    value: org.pk
                }
            }))
        } else {
            setFinanciers([])
        }
    },[props.organisations])

    useEffect(() => {
        if (props.garages) {
            const garages = props.garages
            setGarages(garages.map((garage) => {
                return {
                    ...garage,
                    label: garage.name,
                    value: garage.pk
                }
            }))
        } else {
            setGarages([])
        }
    },[props.garages])

    useEffect(() => {
        if (props.errorMessageForGarageListCollection) {
            showNotifications('error', props.errorMessageForGarageListCollection)
        }
    }, [props.errorMessageForGarageListCollection])

    useEffect(() => {
        if (props.firmwares) {
            const firmwares = props.firmwares
            setInstalledFirmwares(firmwares.map((firmware) => {
                return {
                    ...firmware,
                    label: firmware.version,
                    value: firmware.version
                }
            }))
        } else {
            setInstalledFirmwares([])
        }
    },[props.firmwares])

    useEffect(() => {
        if (props.errorRelatedToFirmwareCollection) {
            showNotifications('error', props.errorRelatedToFirmwareCollection)
        }
    }, [props.errorRelatedToFirmwareCollection])

    const filterForm = () => {
        return <>
            <Form onSubmit={handleSubmit(collectFilteredDongles)}>
                <div className={"row g-3"}>
                    <div className={"col-md-4"}>
                        <Form.Group>
                            <div>
                                <Form.Label>Garage</Form.Label>
                            </div>
                            <Controller
                                control={control}
                                name={"garage_guid"}
                                defaultValue={''}
                                render={( { onChange, onBlur, value, name, ref },
                                          { invalid, isTouched, isDirty }) => (
                                    <Select
                                        placeholder={props.collectingGarages? 'Loading options..':'Select garages'}
                                        name={'garage'} // This name is used to show 'n items selected' text
                                        classNamePrefix="react-select-sol-style"
                                        isDisabled={props.collectingGarages}
                                        isLoading={props.collectingGarages}
                                        maxMenuHeight={200}
                                        isClearable={true}
                                        options={garages}
                                        value={value}
                                        control={control}
                                        inputRef={ref}
                                        isSearchable={true}
                                        isMulti={true}
                                        hideSelectedOptions={false}
                                        styles={customStylesForMultiSelectField}
                                        components={{ ValueContainer, Option }}
                                        noOptionsMessage={() => "No garages available"}
                                        onChange={(selected) => {
                                            onChange(selected)
                                            if (selected) {
                                                setGarages(groupSelectedOptions(selected, garages))
                                            }
                                        }}
                                    />
                                )}
                            />
                        </Form.Group>
                    </div>
                    <div className={"col-md-4"}>
                        <Form.Group>
                            <div>
                                <Form.Label>Installed Firmware</Form.Label>
                            </div>
                            <Controller
                                control={control}
                                name={"firmware_version"}
                                defaultValue={''}
                                render={( { onChange, onBlur, value, name, ref },
                                          { invalid, isTouched, isDirty }) => (
                                    <Select
                                        placeholder={props.listLoading? 'Loading options..':'Select firmware'}
                                        name={'firmware'} // This name is used to show 'n items selected' text
                                        classNamePrefix="react-select-sol-style"
                                        isDisabled={props.listLoading}
                                        isLoading={props.listLoading}
                                        maxMenuHeight={200}
                                        isClearable={true}
                                        options={installedFirmwares}
                                        value={value}
                                        control={control}
                                        inputRef={ref}
                                        isSearchable={true}
                                        isMulti={true}
                                        hideSelectedOptions={false}
                                        styles={customStylesForMultiSelectField}
                                        components={{ ValueContainer, Option }}
                                        noOptionsMessage={() => "No firmwares available"}
                                        onChange={(selected) => {
                                            onChange(selected)
                                            if (selected) {
                                                setInstalledFirmwares(groupSelectedOptions(selected, installedFirmwares))
                                            }
                                        }}
                                    />
                                )}
                            />
                        </Form.Group>
                    </div>
                    <div className={"col-md-4"}>
                        <Form.Group>
                            <div>
                                <Form.Label>Hardware Compatibility</Form.Label>
                            </div>
                            <Controller
                                control={control}
                                name={"hardware_version"}
                                defaultValue={''}
                                render={({ onChange, onBlur, value, name, ref },
                                         { invalid, isTouched, isDirty }) => (
                                    <Select
                                        placeholder={props.hardwareListCollectionInProgress? 'Loading hardware..':'Select hardware'}
                                        name={'hardware'} // This name is used to show 'n items selected' text
                                        classNamePrefix="react-select-sol-style"
                                        isDisabled={props.hardwareListCollectionInProgress}
                                        isLoading={props.hardwareListCollectionInProgress}
                                        maxMenuHeight={200}
                                        isClearable={true}
                                        options={compatibleHwVersions}
                                        value={value}
                                        control={control}
                                        inputRef={ref}
                                        isSearchable={true}
                                        isMulti={true}
                                        hideSelectedOptions={false}
                                        styles={customStylesForMultiSelectField}
                                        components={{ ValueContainer, Option }}
                                        noOptionsMessage={() => "No hardware available"}
                                        onChange={(selected) => {
                                            onChange(selected)
                                            if (selected) {
                                                setCompatibleHwVersions(groupSelectedOptions(selected, compatibleHwVersions))
                                            }
                                        }}
                                    />
                                )}
                            />
                        </Form.Group>
                    </div>
                </div>
                <div className={"row g-3"}>
                    {props.isSolshareUser? <>
                        <div className={"col-md-4"}>
                            <Form.Group>
                                <div>
                                    <Form.Label>Financier</Form.Label>
                                </div>
                                <Controller
                                    control={control}
                                    name={"hardware_version"}
                                    defaultValue={''}
                                    render={({ onChange, onBlur, value, name, ref },
                                             { invalid, isTouched, isDirty }) => (
                                        <Select
                                            placeholder={props.orgListLoading? 'Loading options..':'Select financiers'}
                                            name={'financier'} // This name is used to show 'n items selected' text
                                            classNamePrefix="react-select-sol-style"
                                            isDisabled={props.orgListLoading}
                                            isLoading={props.orgListLoading}
                                            maxMenuHeight={200}
                                            isClearable={true}
                                            options={financiers}
                                            value={value}
                                            control={control}
                                            inputRef={ref}
                                            isSearchable={true}
                                            isMulti={true}
                                            hideSelectedOptions={false}
                                            styles={customStylesForMultiSelectField}
                                            components={{ ValueContainer, Option }}
                                            noOptionsMessage={() => "No financiers available"}
                                            onChange={(selected) => {
                                                setValue('garage_guid', '')
                                                onChange(selected)
                                                if (selected) {
                                                    setFinanciers(groupSelectedOptions(selected, financiers))
                                                    GET_GARAGE_LIST({'organization_guid': formulateApiRequestDataFromSelectedItems(selected)})
                                                } else {
                                                    GET_GARAGE_LIST()
                                                }
                                            }}
                                        />
                                    )}
                                />
                            </Form.Group>
                        </div>
                    </>:''}
                    <div className={"col-md-8"}>
                        <Form.Group>
                            <div>
                                <Form.Label>SOLdongle ID</Form.Label>
                            </div>
                            <Controller
                                control={control}
                                name="serial_number"
                                rules={{}}
                                render={({ onChange, onBlur, value, name, ref },
                                         { invalid, isTouched, isDirty }) => (
                                    <SolTagInput
                                        placeholder="Type SOLdongle IDs with space Ex: 44000382 44000383"
                                        disabled={false}
                                        onChange={onChange} // Pass onChange to SolTagInput
                                        value={value}
                                        height={"38px"}
                                    />
                                )}
                            />
                            {errors.serial_number && <div className="text-danger">
                                <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.serial_number.message}</div>}
                        </Form.Group>
                    </div>
                </div>
                <div className={'row'}>
                    <div className={"col-md-12"}>
                        <Button variant="outline-warning" size={'sm'} type="submit"
                                disabled={props.collectingDongles || props.dongleAssignmentIsInProgress}>
                            Preview SOLdongles
                        </Button>
                    </div>
                </div>
            </Form>
        </>
    }

    /**
     * Validates each comma-separated item in a given string to ensure it meets specific criteria.
     * The function splits the string into individual items, trims whitespace from each item,
     * and checks if each item matches a regex pattern requiring:
     *  - Only numbers
     *  - Starts with "44"
     *  - Is exactly 8 digits in total
     * Validation stops and returns false as soon as an invalid item is found.
     *
     * @param {string} value - The input string containing comma-separated items to validate.
     * @returns {boolean} - Returns true if all items are valid, false if any item is invalid.
     *
     * @example
     * const result = validateItems("44123456, 44123457");
     * console.log(result); // true
     *
     * @example
     * const result = validateItems("44123456, 12345678");
     * console.log(result); // false
     *
     * @example
     * const result = validateItems("44123456, 4412345");
     * console.log(result); // false
     */
    const validateItems = (value) => {
        const regex = /^44\d{6}$/
        const items = value.split(',').map(item => item.trim());

        for (let item of items) {
            if (!regex.test(item)) {
                return false; // Stop and return false if any item is invalid
            }
        }

        return true; // All items passed validation
    }

    const collectFilteredDongles = (data) => {
        if (data.serial_number && !validateItems(data.serial_number)) {
            setError("serial_number", {"message": "An ID should be a 8 digit number and starts with 44!"})
        } else {
            let filter = {}
            filter["serial_number"] = data.serial_number? data.serial_number:''
            filter["garage_guid"] = data.garage_guid? formulateApiRequestDataFromSelectedItems(data.garage_guid):''
            filter["firmware_version"] = data.firmware_version? formulateApiRequestDataFromSelectedItems(data.firmware_version, 'value'):''
            filter["hardware_version"] = data.hardware_version? formulateApiRequestDataFromSelectedItems(data.hardware_version, 'value'):''
            filter["financier_guid"] = data.financier_guid? formulateApiRequestDataFromSelectedItems(data.financier_guid):''
            filter["tab_identifier"] = apiKeysAndTabValueMap[tabValue]
            filter["new_firmware_version"] = mainFirmware
            setFilterParam(filter)
            COLLECT_DONGLES_BASED_ON_OTA_ELIGIBILITY(filter)
        }
    }

    // ----- End: Filtering process related ---- //

    // ----- Begin: Tab and SOLdongle list related ---- //
    useEffect(() => {
        if (props.dongles) {
            if (tabValue === 0) {
                setEligibleDongles(props.dongles)
                setEligibleDongleListLabel("Eligible SOLdongles (" + props.dongles.length + ")")
            } else if (tabValue === 1) {
                setActiveDongles(props.dongles)
                setActiveDongleListLabel("Active SOLdongles (" + props.dongles.length + ")")
            } else {
                setAllowedDongles(props.dongles)
                setAllowedDongleListLabel("Allowed SOLdongles (" + props.dongles.length + ")")
            }
        } else {
            setEligibleDongles(undefined)
            setEligibleDongleListLabel("Eligible SOLdongles")

            setActiveDongles(undefined)
            setActiveDongleListLabel("Active SOLdongles")

            setAllowedDongles(undefined)
            setAllowedDongleListLabel("Allowed SOLdongles")
        }
    },[props.dongles])

    useEffect(() => {
        if (props.errorMessageForDongleCollection) {
            showNotifications("error", props.errorMessageForDongleCollection)
            if (tabValue === 0) {
                setEligibleDongles([])
            } else if (tabValue === 1) {
                setActiveDongles([])
            } else {
                setAllowedDongles([])
            }
        }
    },[props.errorMessageForDongleCollection])

    const handleTabSelection = (event, newValue) => {
        setTabValue(newValue)
        let filter = filterParam
        filter["tab_identifier"] = apiKeysAndTabValueMap[newValue]
        setFilterParam(filter)
        COLLECT_DONGLES_BASED_ON_OTA_ELIGIBILITY(filter)
        if (newValue === 1) {
            setShowOldFwColumn(true)
        } else {
            setShowOldFwColumn(false)
        }
    }

    const onDongleSelection = (data) => {
        setShowError(false)
        setSelectedDongles(data)
    }

    const seeUpdateHistory = (data) => {
        GET_FIRMWARE_UPDATE_LIST({
            dongle_serial_number: data.serial_number
        })
        setDongleForModal(data.serial_number)
        setShowFwUpdateHistoryModal(true)
    }

    const hideUpdateHistoryModal = () => {
        setShowFwUpdateHistoryModal(false)
    }

    useEffect(() => {
        if (props.otaList) {
            const updates = props.otaList
            setHistories(updates.map((fwUpdate) => {
                return {
                    ...fwUpdate
                }
            }))
        } else {
            setHistories(undefined)
        }
    },[props.otaList])

    useEffect(() => {
        if (props.errorForOtaListCollection) {
            setHistories([])
        }
    }, [props.errorForOtaListCollection])

    const renderUpdateHistoryModal = () => {
        return <>
            <GenericModal
                size={'lg'}
                showModalHeader={true}
                footer={false}
                hideModal={hideUpdateHistoryModal}
                modal={showFwUpdateHistoryModal}
                title={<h1><strong>Firmware update history of <span style={{color: "#F18D00"}}>{dongleForModal}</span></strong></h1>}>
                <div className={"row"}>
                    <div className={"col-md-12"}>
                        {!props.collectingOtaList && histories? <>
                            <DataTableContainerForDongleList>
                                <FwUpdateHistoriesTable histories={histories} pageSize={5}/>
                            </DataTableContainerForDongleList>
                        </>:<>
                            <Typography variant="h5">
                                <Skeleton className={"d-flex justify-content-center align-items-center"}
                                          variant="rect" sx={{ color: '#F18D00' }} width={'100%'}
                                          height={383}>
                                    Collecting firmware update list ...
                                </Skeleton>
                            </Typography>
                        </>}
                    </div>
                </div>
                <div className={'row mt-3'}>
                    <div className={'col-md-12'}>
                        <Button variant={"outline-dark"} size={'md'} onClick={hideUpdateHistoryModal}
                                className={'float-right'} disabled={false}>
                            Close
                        </Button>
                    </div>
                </div>
            </GenericModal>
        </>
    }
    // ----- End: Tab and SOLdongle list related ---- //

    // ----- Begin: Page action related ---- //
    const closeThePage = () => {
        props.history.push({
            pathname: '/ota/firmwares',
            initialPage: lastVisitedPage // Passing this so that FW list table gets initiated at the last visited page
        })
    }

    const allowFwUpdate = () => {
        if (selectedDongles.length === 0) {
            setShowError(true)
        } else {
            const payload = {
                "dongle_ids": selectedDongles.map((item) => item.serial_number),
                "firmware_version": mainFirmware
            }
            ASSIGN_FW_TO_DONGLES(payload)
        }
    }

    useEffect(() => {
        if (props.donglesAssigned === requestCycle.success) {
            toast.success("Firmware assignment is successfull!")
            COLLECT_DONGLES_BASED_ON_OTA_ELIGIBILITY(filterParam)
        }
    }, [props.donglesAssigned])

    useEffect(() => {
        if (props.errorMessageForDongleAssignment) {
            showNotifications('error', props.errorMessageForDongleAssignment)
        }
    }, [props.errorMessageForDongleAssignment])
    // ----- End: Page action related ---- //

    return (
        <ContentWrapper isLoading={false} pageTitle={"Firmware List"} showBackButton={false} showCardHeader={false}>
            <div className={"row"}>
                <div className={"col-md-12"}>
                    <Toolbar>
                        <Toolbar.Title>
                            <h1><strong>Assign Dongles for firmware {mainFirmware? <>v{mainFirmware}</>:''}</strong></h1>
                        </Toolbar.Title>
                    </Toolbar>
                    <hr/>
                </div>
            </div>
            {/* Filter form */}
            <div className={"row mt-6"}>
                <div className={"col-md-12"}>
                    <Form.Label><strong>Select SOLdongles</strong></Form.Label>
                </div>
            </div>
            <div className={"row mt-3"}>
                <div className={"col-md-12"}>
                    <Box sx={{border: '1px solid #E9E9E9', padding: '16px', borderRadius: '16px'}}>
                        <div className={"row mb-5"}>
                            <div className={"col-md-12"}>
                                SELECTION CRITERIA
                            </div>
                        </div>
                        {filterForm()}
                    </Box>
                </div>
            </div>
            {/* Table */}
            <div className={"row mt-10"}>
                <div className={"col-md-12"}>
                    <Tabs
                        value={tabValue}
                        onChange={handleTabSelection}
                        aria-label="operational logs"
                        classes={{ indicator: classes.indicator}}
                    >
                        <Tab label={eligibleDongleListLabel} className={classes.tab}/>
                        <Tab label={activeDongleListLabel} className={classes.tab}/>
                        <Tab label={allowedDongleListLabel} className={classes.tab}/>
                    </Tabs>
                    <TabPanel value={tabValue} index={0}>
                        <div className={"row"}>
                            <div className={"col-md-12"}>
                                {!props.collectingDongles && eligibleDongles? <>
                                    <DataTableContainerForDongleList>
                                        <DataTable
                                            language={language}
                                            noDataAvailableMessageInEnglish={'No dongles available'}
                                            columns={dongleListColumns}
                                            data={eligibleDongles}
                                            showToolbar={false}
                                            asyncPagination={false}
                                            pagination={true}
                                            pageSize={5}
                                            selection={true}
                                            showSelectAllCheckbox={true}
                                            onSelectionChange={onDongleSelection}
                                            actionColumnIndex={-1}
                                            overrideCustomActions={true}
                                            actions={[
                                                {
                                                    position: "row",
                                                    action: (rowData) => ({
                                                        icon: () => {
                                                            return <Button variant={'warning'}
                                                                           size={'sm'}
                                                                           style={{ whiteSpace: 'nowrap' }}>
                                                                See Update History
                                                            </Button>
                                                        },
                                                        onClick: (e, row) => {seeUpdateHistory(row)},
                                                    })
                                                }
                                            ]}
                                        />
                                    </DataTableContainerForDongleList>
                                </>:<>
                                    <Typography variant="h5">
                                        <Skeleton className={"d-flex justify-content-center align-items-center"}
                                                  variant="rect" sx={{ color: '#F18D00' }} width={'100%'}
                                                  height={375}>
                                            Collecting eligible dongles ...
                                        </Skeleton>
                                    </Typography>
                                </>}
                            </div>
                        </div>
                        {showError? <>
                            <div className={'row mt-3'}>
                                <div className={"col-md-12"}>
                                    <Alert severity="error" variant={"outlined"} onClose={() => {setShowError(false)}}>Please select atleast one dongle!</Alert>
                                </div>
                            </div>
                        </>:null}
                        <div className={"row mt-3"}>
                            <Button type={"button"} variant={"warning"} size={"sm"} className={"ml-3"}
                                    onClick={allowFwUpdate} disabled={props.dongleAssignmentIsInProgress}>
                                {props.dongleAssignmentIsInProgress? <><Spinner animation={'border'} size={'sm'} variant={'light'}/>&nbsp;</>:''}Allow Firmware Update
                            </Button>
                            <Button type={"button"} variant={"outline-dark"} size={"sm"} className={"ml-3"}
                                    onClick={closeThePage} disabled={props.dongleAssignmentIsInProgress}>Close</Button>
                        </div>
                    </TabPanel>
                    <TabPanel value={tabValue} index={1}>
                        {!props.collectingDongles && activeDongles? <>
                            <DataTableContainerForDongleList>
                                <DataTable
                                    language={language}
                                    noDataAvailableMessageInEnglish={'No dongles available'}
                                    columns={dongleListColumns}
                                    data={activeDongles}
                                    showToolbar={false}
                                    asyncPagination={false}
                                    pagination={true}
                                    pageSize={5}
                                    actionColumnIndex={-1}
                                    overrideCustomActions={true}
                                    actions={[
                                        {
                                            position: "row",
                                            action: (rowData) => ({
                                                icon: () => {
                                                    return <Button variant={'warning'}
                                                                   size={'sm'}
                                                                   style={{ whiteSpace: 'nowrap' }}>
                                                        See Update History
                                                    </Button>
                                                },
                                                onClick: (e, row) => {seeUpdateHistory(row)},
                                            })
                                        }
                                    ]}
                                />
                            </DataTableContainerForDongleList>
                        </>:<>
                            <Typography variant="h5">
                                <Skeleton className={"d-flex justify-content-center align-items-center"}
                                          variant="rect" sx={{ color: '#F18D00' }} width={'100%'}
                                          height={350}>
                                    Collecting active dongles ...
                                </Skeleton>
                            </Typography>
                        </>}
                    </TabPanel>
                    <TabPanel value={tabValue} index={2}>
                        {!props.collectingDongles && allowedDongles? <>
                            <DataTableContainerForDongleList>
                                <DataTable
                                    language={language}
                                    noDataAvailableMessageInEnglish={'No dongles available'}
                                    columns={dongleListColumns}
                                    data={allowedDongles}
                                    showToolbar={false}
                                    asyncPagination={false}
                                    pagination={true}
                                    pageSize={5}
                                    actionColumnIndex={-1}
                                    overrideCustomActions={true}
                                    actions={[
                                        {
                                            position: "row",
                                            action: (rowData) => ({
                                                icon: () => {
                                                    return <Button variant={'warning'}
                                                                   size={'sm'}
                                                                   style={{ whiteSpace: 'nowrap' }}>
                                                        See Update History
                                                    </Button>
                                                },
                                                onClick: (e, row) => {seeUpdateHistory(row)},
                                            })
                                        }
                                    ]}
                                />
                            </DataTableContainerForDongleList>
                        </>:<>
                            <Typography variant="h5">
                                <Skeleton className={"d-flex justify-content-center align-items-center"}
                                          variant="rect" sx={{ color: '#F18D00' }} width={'100%'}
                                          height={370}>
                                    Collecting allowed dongles ...
                                </Skeleton>
                            </Typography>
                        </>}
                    </TabPanel>
                </div>
            </div>
            {renderUpdateHistoryModal()}
        </ContentWrapper>
    );
};

AssignDongles.propTypes = {};

const mapStateToProps = (state) => {
    return {
        language: state.auth.language,
        authorization: state.auth.authorizations,
        isSolshareUser: state.auth.is_solshare_user,
        hardwareListCollectionInProgress: state.otaReducer.hardwareListCollectionInProgress,
        hardwareList: state.otaReducer.hardwareList,
        hardwareListCollectionError: state.otaReducer.hardwareListCollectionError,
        orgListLoading: state.commonReducer.orgListLoading,
        organisations: state.commonReducer.organisations,
        collectingGarages: state.commonReducer.collectingGarages,
        garages: state.commonReducer.garages,
        errorMessageForGarageListCollection: state.commonReducer.errorMessageForGarageListCollection,
        listLoading: state.otaReducer.listLoading,
        firmwares: state.otaReducer.firmwares,
        errorRelatedToFirmwareCollection: state.otaReducer.errorRelatedToFirmwareCollection,
        otaList: state.otaReducer.otaList,
        collectingOtaList: state.otaReducer.collectingOtaList,
        errorForOtaListCollection: state.otaReducer.errorForOtaListCollection,
        dongles: state.otaReducer.dongles,
        errorMessageForDongleCollection: state.otaReducer.errorMessageForDongleCollection,
        collectingDongles: state.otaReducer.collectingDongles,
        donglesAssigned: state.otaReducer.donglesAssigned,
        dongleAssignmentIsInProgress: state.otaReducer.dongleAssignmentIsInProgress,
        errorMessageForDongleAssignment: state.otaReducer.errorMessageForDongleAssignment
    }
}

export default connect(mapStateToProps, {...actions, ...commonActions})(AssignDongles);
