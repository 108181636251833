import React, {useEffect, useState} from 'react';
import {useLocation} from 'react-router';
import PropTypes from 'prop-types';
import ContentWrapper from "../../../../components/contentWrapper/contentWrapper";
import {Button, Form, Spinner} from "react-bootstrap";
import {SolInput} from "../../../../components/SolStyledComponents/components";
import Select from "react-select";
import {useForm, Controller} from "react-hook-form";
import {connect} from "react-redux";
import {actions} from "../../actions";
import {requestCycle} from "../../utils";
import {toast} from "react-toastify";
import {Toolbar} from "../../../users/views/components";
import {useSubheader} from "../../../../../_metronic/layout";
import { da } from 'date-fns/locale';
import {customStylesForSingleSelectField} from "../../../../utils/reactSelectRelated/customStylesForSingleSelect";
import SolRadioInput from "../../../../components/solradioinput/SolRadioInput";
import {showNotifications} from "../../../../utils/notification";
import {customStylesForMultiSelectField} from "../../../../utils/reactSelectRelated/customStylesForMultiSelect";
import {ValueContainer} from "../../../../utils/reactSelectRelated/showCustomizedValueLabelForMultiSelect";
import {Option} from "../../../../utils/reactSelectRelated/showOptionsWithCheckbox";
import {groupSelectedOptions} from "../../../../utils/reactSelectRelated/groupSelectedOptionsForMultiSelect";
import {
    formulateApiRequestDataFromSelectedItems
} from "../../../../utils/multiSelectValueFormation/formatForApiRequest";
import {SolTagInput} from "../../../../components/solTagInput/SolTagInput"


const CreateOrEditFirmware = props => {
    const validFirmwareTypes=[
        "firmware",
        "bootloader",
        "test_firmware",
        "initial_firmware",
    ]

    const firmwareTypes = [
        {value: validFirmwareTypes[0], label: "Operation Firmware"},
        {value: validFirmwareTypes[1], label: "Bootloader Firmware"},
        { value: validFirmwareTypes[2], label: "Test Firmware" },
        { value: validFirmwareTypes[3], label: "Initial Firmware" },
    ]

    const {register, handleSubmit, errors, setError, clearErrors,control, setValue, getValues} = useForm();
    const [language, setLanguage] = useState('EN')
    const location = useLocation()
    const subHeader = useSubheader();

    const [edit, setEdit] = useState(false)
    const [firmwareGuid, setFirmwareGuid] = useState('')
    const [lastVisitedPage, setLastVisitedPage] = useState('')
    const [version, setVersion] = useState('')
    const [status, setStatus] = useState('active')
    const [hardwares, setHardwares] = useState([])
    const [currentHardware, setCurrentHardware] = useState([])
    const [hardwareValuesAreSorted, setHardwareValuesAreSorted] = useState(false)
    const [currentAppVersions, setCurrentAppVersions] = useState([])
    const [appVersions, setAppVersions] = useState([{
        label: "2.8",
        value: "2.8",
        pk: "2.8"
    }, {
        label: "2.7",
        value: "2.7",
        pk: "2.7"
    }, {
        label: "2.6",
        value: "2.6",
        pk: "2.6"
    }])
    const informationIsRequiredMsg = "Above information is required!"

    const {
        GET_HARDWARE_LIST,
        CREATE_FIRMWARE_LIST, // TODO: Need to rename this action
        UPDATE_FIRMWARE,
        RETURN_TO_INITIAL
    } = props

    useEffect(() => {
        setLanguage(props.language)
        subHeader.setActionButtons(null)
        if (location.purpose && location.purpose === 'create') {
            clearSessionStorages()
            setEdit(false)
            sessionStorage.setItem('edit', false)
        }

        if (location.firmwareData) {
            // Edit state
            sessionStorage.setItem('edit', true)
            setEdit(true)
            // Serial of the page where FW is represented
            let page = location.page
            sessionStorage.setItem('lastVisitedPage', page)
            setLastVisitedPage(page)
            // Firmware Guid
            let firmware = location.firmwareData.pk
            sessionStorage.setItem('pk', firmware)
            setFirmwareGuid(firmware)
            // Firmware Version
            let version = location.firmwareData.version
            setVersion(version)
            setValue("version", version)
            sessionStorage.setItem("version", version)
            // Firmware Type
            let fwType = validFirmwareTypes.includes(location.firmwareData.entity_type)?
                firmwareTypes.filter((type) => type.value === location.firmwareData.entity_type)[0]:''
            setValue("type", fwType)
            sessionStorage.setItem("type", fwType.value)
            // Compatible Hardware
            sessionStorage.setItem("hardware", JSON.stringify(location.firmwareData.hardware_version_list))
            setCurrentHardware(location.firmwareData.hardware_version_list)
            // App Versions
            let compatibleAppVersions = location.firmwareData.compatible_app_version_list
            sessionStorage.setItem("compatibleAppVersions", compatibleAppVersions)
            setValue("compatible_app_version_list", compatibleAppVersions)
            // Firmware Status
            let firmwareStatus = location.firmwareData.status
            sessionStorage.setItem("status", firmwareStatus)
            setStatus(firmwareStatus)
        } else if (sessionStorage.getItem("edit") === 'true') {
            // Edit state
            setEdit(true)
            // Serial of the page where FW is represented
            setLastVisitedPage(sessionStorage.getItem('lastVisitedPage'))
            // Firmware Guid
            setFirmwareGuid(sessionStorage.getItem('pk'))
            // Version
            setVersion(sessionStorage.getItem("version"))
            setValue("version", sessionStorage.getItem("version"))
            // Firmware Type
            let fwType = validFirmwareTypes.includes(sessionStorage.getItem("type"))?
                firmwareTypes.filter((type) => type.value === sessionStorage.getItem("type"))[0]:''
            setValue("type", fwType)
            // Compatible Hardware
            setCurrentHardware(JSON.parse(sessionStorage.getItem("hardware")))
            // App Versions
            setValue("compatible_app_version_list", sessionStorage.getItem("compatibleAppVersions"))
            // Firmware Status
            setStatus(sessionStorage.getItem("status"))
        }

        GET_HARDWARE_LIST()
        return ()=> {
            RETURN_TO_INITIAL()
        }
    },[])

    useEffect(() => {
        /**
         * Load options for the "Compatible Hardware" field from the response of the "GET_HARDWARE_LIST" API
         * The effect runs on changes of the API response which is actually property "hardwareList" from the "otaReducer"
         * Also when the value of "currentHardware" changes
         */
        if (props.hardwareList) {
            let selectedHardware = []
            setHardwares(props.hardwareList.map((hardware) => {
                let version = hardware.version
                let option = {
                    ...hardware,
                    label: version,
                    value: version
                }
                if (currentHardware.includes(version)) {
                    // Check the hardware version matched with any version saved at sessionStorage
                    selectedHardware.push(option)
                }
                return option
            }))
            // Make valid (exist within API response) hardware saved at sessionStorage, selected
            setValue("hardware_version_list", selectedHardware)
        }
    }, [props.hardwareList, currentHardware])

    useEffect(() => {
        /**
         * Sort and bring the current compatible hardware at top of the "available options" during firmware edit process
         * The effect runs on changes of the state variable 'hardwares'
         */
        if (hardwares.length > 0 && getValues("hardware_version_list").length > 0 && !hardwareValuesAreSorted) {
            setHardwares(groupSelectedOptions(getValues("hardware_version_list"), hardwares))
            setHardwareValuesAreSorted(true) // This ensures that this runs only once, otherwise sorting will continue recursively
        }
    }, [hardwares])

    useEffect(() => {
        if (props.hardwareListCollectionError) {
            showNotifications('error', props.hardwareListCollectionError)
        }
    }, [props.hardwareListCollectionError])

    useEffect(() => {
        /**
         * Sort and bring the current app versions at top of the "available options" during firmware edit process
         * The effect runs on changes of the state variable 'currentAppVersions' which is actually
         * It runs one time only when the edit page is being loaded
         */
        if (currentAppVersions.length > 0) {
            setAppVersions(groupSelectedOptions(currentAppVersions, appVersions))
        }
    }, [currentAppVersions])

    useEffect(() => {
        if (props.firmwareCreated === requestCycle.success) {
            toast.success("Firmware Created Successfully!")
            props.history.push('/ota/firmwares')
        }
    }, [props.firmwareCreated])

    /**
     * Show error message related to firmware create API call, if any
     *
     */
    useEffect(() => {
        if (props.errorMessageForFirmwareCreation) {
            showNotifications('error', props.errorMessageForFirmwareCreation)
        }
    }, [props.errorMessageForFirmwareCreation]) // Run each time props.errorMessageForFirmwareCreation gets updated, normally during each FW create API call

    useEffect(() => {
        if (props.firmwareUpdated === requestCycle.success) {
            toast.success("Firmware is updated Successfully!")
            props.history.push({
                pathname: '/ota/firmwares',
                initialPage: lastVisitedPage // Passing this so that FW list table gets initiated at the last visited page
            })
        }
    }, [props.firmwareUpdated])

    useEffect(() => {
        if (props.errorMessageForFirmwareUpdate) {
            showNotifications('error', props.errorMessageForFirmwareUpdate)
        }
    }, [props.errorMessageForFirmwareUpdate])

    const clearSessionStorages = () => {
        sessionStorage.removeItem('pk')
        sessionStorage.removeItem('version')
        sessionStorage.removeItem('status')
        sessionStorage.removeItem('type')
        sessionStorage.removeItem('hardware')
        sessionStorage.removeItem('compatibleAppVersions')
        sessionStorage.removeItem('status')
    }

    const validateFileBasedOnFirmwareType = (data)=>{
        const hex_file = ["bootloader", "test_firmware"]

        if (hex_file.indexOf(data["type"]) !== -1 && !data?.firmware_file.name?.endsWith('.hex')) {
            setError("firmware_file", {
                message: "Not a valid hex file"
            })
            return
        } else if (hex_file.indexOf(data["type"]) === -1 && !data?.firmware_file.name?.endsWith('.zip')) {
            setError("firmware_file", {
                message: "Not a valid zip file"
            })
            return
        }

        return true
    }

    const handleStatusSelectionChange = (event) => {
        setStatus(event.target.value)
    }

    /**
     * Validates each comma-separated item in a given string.
     * The function splits the string into items, trims whitespace,
     * and checks each item against a specified regex pattern.
     * Validation stops and returns false as soon as an invalid item is encountered.
     *
     * @param {string} value - The input string containing comma-separated items to validate.
     * @returns {boolean} - Returns true if all items are valid, false if any item is invalid.
     *
     * @example
     * const result = validateItems("1.1, 1.2, 1.3");
     * console.log(result); // true
     *
     * @example
     * const result = validateItems("1.1, 1.1_2, test-item");
     * console.log(result); // true
     *
     * @example
     * const result = validateItems("1.1, 1.1 2");
     * console.log(result); // false
     **/
    const validateItems = (value) => {
        const regex = /^[A-Za-z0-9._,]+$/;
        const items = value.split(',').map(item => item.trim());

        for (let item of items) {
            if (!regex.test(item)) {
                return false; // Stop and return false if any item is invalid
            }
        }

        return true; // All items passed validation
    }

    /**
     * Formats an array of items [selected hardware objects] into a comma-separated string of `label` [versions] values
     *
     * @function formulateApiRequestDataForSelectedHardwares
     * @param {Array<Object>} items - Array of objects, each with a `label` and `value` property.
     * @returns {string} A comma-separated string of all `label` values from the input array.
     *
     * @example
     * const items = [{"label": "a", "value": 1}, {"label": "b", "value": 2}];
     * const result = formulateApiRequestDataForSelectedHardwares(items);
     * console.log(result); // Output: "a,b"
     */
    const formulateApiRequestDataForSelectedHardwares= (items) => {
        let values = ''
        for (let i=0; i<items.length; i++) {
            if (i === 0) {
                values += items[i]['label']
            } else {
                values += ',' + items[i]['label']
            }
        }
        return values
    }

    const createFirmware = (data) => {
        data = {
            ...data,
            status: status,
            type: data.type?.value,
            firmware_file: data.firmware_file['0']
        }
        if (!validateFileBasedOnFirmwareType(data)) return
        CREATE_FIRMWARE_LIST(data)
    }

    const updateFirmware =(data)=>{
        data = {
            ...data,
            version: version,
            pk: firmwareGuid,
            type: data.type?.value,
            status: status
        }
        if (data.firmware_file['0']) {
            data = {
                ...data,
                firmware_file: data.firmware_file['0']
            }
            if (!validateFileBasedOnFirmwareType(data)) return
        }
        UPDATE_FIRMWARE(data);
    }

    const onSubmit=(data)=>{
        const isValid = validateItems(data.compatible_app_version_list)
        if (!isValid) {
            setError("compatible_app_version_list", {"message": "Only alphanumeric characters, '_' and '.' are allowed."})
        } else {
            data["hardware_version_list"] = data["hardware_version_list"]? formulateApiRequestDataForSelectedHardwares(data["hardware_version_list"]):""
            if (!edit) {
                createFirmware(data)
            } else {
                updateFirmware(data)
            }
        }
    }

    return (
        <ContentWrapper pageTitle={''} showCardHeader={false} showBackButton={false} isLoading={false}>
            <div className={"row mb-6"}>
                <div className={"col-md-12"}>
                    <Toolbar>
                        <Toolbar.Title>
                            <h1><b>{!edit? "Create Firmware":"Update Firmware"}</b></h1>
                        </Toolbar.Title>
                    </Toolbar>
                </div>
            </div>

            <div className={"row"}>
                <div className={"col-md-12"}>
                    <Form onSubmit={handleSubmit(onSubmit)}>

                        {/* Firmware version */}
                        <div className={'row g-3'}>
                            <div className={"col-md-6"}>
                                <Form.Group>
                                    <Form.Label>Firmware Version <span className="required text-danger">*</span></Form.Label>
                                    <SolInput
                                        type="text"
                                        name={"version"}
                                        disabled={!!edit}
                                        ref={register({
                                            required: informationIsRequiredMsg,
                                            pattern: {
                                                value: /^\d+(\.\d+)*$/i,
                                                message: "Invalid firmware version!"
                                            }
                                        })}
                                        placeholder={language === "EN"? "Enter Firmware Version. Ex: 1.1, 1.1.1":"ফার্মওয়্যার ভার্সন লিখুন।উদাহরণঃ 1.1, 1.1.1"}
                                        autoComplete={"off"}
                                    />
                                    {errors.version && <div className="text-danger">
                                        <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.version.message}</div>}
                                </Form.Group>
                            </div>
                        </div>

                        {/* Firmware type */}
                        <div className={'row g-3'}>
                            <div className={"col-md-6"}>
                                <Form.Group>
                                    <div>
                                        <Form.Label>Firmware Type <span className="required text-danger">*</span></Form.Label>
                                    </div>
                                    <Controller
                                        control={control}
                                        name={"type"}
                                        rules= {{ required: informationIsRequiredMsg }}
                                        defaultValue={firmwareTypes[0]}
                                        render={( { onChange, onBlur, value, name, ref },
                                                  { invalid, isTouched, isDirty }) => (
                                            <Select
                                                name={`type`}
                                                placeholder={language === 'EN'? 'Select a Firmware type':'ব্যাটারি মডেল বাছাই করুন'}
                                                classNamePrefix="react-select-sol-style"
                                                // isDisabled={props.batteryModelListDropDownLoading}
                                                // isLoading={props.batteryModelListDropDownLoading}
                                                maxMenuHeight={200}
                                                value={value}
                                                isClearable={true}
                                                control={control}
                                                inputRef={ref}
                                                options={firmwareTypes}
                                                isSearchable={true}
                                                styles={customStylesForSingleSelectField}
                                                onChange={(selected, {action}) => {
                                                    onChange(selected)
                                                }}
                                            />
                                        )}
                                    />
                                    {errors.type && <div className="text-danger">
                                        <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.type.message}</div>}
                                </Form.Group>
                            </div>
                        </div>

                        {/* Compatible Hardware */}
                        <div className={'row g-3'}>
                            <div className={"col-md-6"}>
                                <Form.Group>
                                    <div>
                                        <Form.Label>Compatible Hardware <span className="required text-danger">*</span></Form.Label>
                                    </div>
                                    <Controller
                                        control={control}
                                        name={"hardware_version_list"}
                                        rules= {{ required: informationIsRequiredMsg }}
                                        defaultValue={''}
                                        render={( { onChange, onBlur, value, name, ref },
                                                  { invalid, isTouched, isDirty }) => (
                                            <Select
                                                placeholder={props.hardwareListCollectionInProgress? 'Loading hardware..':'Select hardware'}
                                                name={'hardware'} // This name is used to show 'n items selected' text
                                                classNamePrefix="react-select-sol-style"
                                                isDisabled={props.hardwareListCollectionInProgress}
                                                isLoading={props.hardwareListCollectionInProgress}
                                                maxMenuHeight={200}
                                                isClearable={true}
                                                options={hardwares}
                                                value={value}
                                                control={control}
                                                inputRef={ref}
                                                isSearchable={true}
                                                isMulti={true}
                                                hideSelectedOptions={false}
                                                styles={customStylesForMultiSelectField}
                                                components={{ ValueContainer, Option }}
                                                noOptionsMessage={() => "No hardware available"}
                                                onChange={(selected) => {
                                                    onChange(selected)
                                                    if (selected) {
                                                        setHardwares(groupSelectedOptions(selected, hardwares))
                                                    }
                                                }}
                                            />
                                        )}
                                    />
                                    {errors.hardware_version_list && <div className="text-danger">
                                        <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.hardware_version_list.message}</div>}
                                </Form.Group>
                            </div>
                        </div>

                        {/* Compatible App Version */}
                        <div className={'row g-3'}>
                            <div className={"col-md-6"}>
                                <Form.Group>
                                    <div>
                                        <Form.Label>Compatible App Version <span className="required text-danger">*</span></Form.Label>
                                    </div>
                                    <Controller
                                        control={control}
                                        name="compatible_app_version_list"
                                        rules={{
                                            required: informationIsRequiredMsg,
                                        }}
                                        render={({ onChange, onBlur, value, name, ref },
                                                 { invalid, isTouched, isDirty }) => (
                                            <SolTagInput
                                                placeholder="Enter app versions with one space between them. Ex: 1.1 2.1.1"
                                                disabled={false}
                                                onChange={onChange} // Pass onChange to SolTagInput
                                                value={value}
                                            />
                                        )}
                                    />
                                    {errors.compatible_app_version_list && <div className="text-danger">
                                        <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.compatible_app_version_list.message}</div>}
                                </Form.Group>
                            </div>
                        </div>

                        {/* Status */}
                        <div className={'row g-3'}>
                            <div className={"col-md-6"}>
                                <Form.Group>
                                    <div className="form-check form-check-inline mt-2">
                                        <SolRadioInput
                                            {...register("status")}
                                            id="active"
                                            value={`active`}
                                            handleChange={(event) => handleStatusSelectionChange(event)}
                                            checked={status === "active"}
                                            htmlFor="active"
                                            labelName="Active"
                                        />
                                    </div>
                                    <div className="form-check form-check-inline mt-2">
                                        <SolRadioInput
                                            {...register("status")}
                                            id="inactive"
                                            value={`inactive`}
                                            handleChange={(event) => handleStatusSelectionChange(event)}
                                            checked={status === "inactive"}
                                            htmlFor="inactive"
                                            labelName="Inactive"
                                        />
                                    </div>
                                </Form.Group>
                            </div>
                        </div>

                        {/* File upload */}
                        <div className={'row g-3'}>
                            <div className={"col-md-6"}>
                                <Form.Group>
                                    <Form.Control
                                        type="file"
                                        name={'firmware_file'}
                                        ref={register({
                                            required: !edit? informationIsRequiredMsg: false,
                                        })}
                                    />
                                    {errors.firmware_file && <div className="text-danger">
                                        <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.firmware_file.message}</div>}
                                </Form.Group>
                                <hr/>
                            </div>
                        </div>

                        <div className={'row'}>
                            <div className={"col-md-12"}>
                                <Button variant={"warning"} type="submit" disabled={props.firmwareInfoSubmitting} size={"md"}>
                                    {props.firmwareInfoSubmitting? <><Spinner animation={'border'} size={'sm'} variant={'light'}/>&nbsp;</>:null}Save
                                </Button>
                                <Button variant={"outline-dark"} size={'md'} onClick={() => {
                                    clearSessionStorages()
                                    props.history.push({
                                        pathname: '/ota/firmwares',
                                        initialPage: lastVisitedPage // Passing this so that FW list table gets initiated at the last visited page
                                    })
                                }} className={"ml-3"} disabled={props.firmwareInfoSubmitting}>
                                    Close
                                </Button>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
            <style jsx>{`
              .react-select-sol-style__control:hover, .react-select-sol-style__control:focus, .react-select-sol-style__control:active{
                    border: 1px solid #F18D00;
                    box-shadow: 0 0 0 3.2px rgba(241, 141, 0, 0.1);
                    outline: none;  
                    }
                    
                .custom-popover{
                  min-width: fit-content;
                  min-height: fit-content;
                }
            `}</style>


        </ContentWrapper>
    );
};

CreateOrEditFirmware.propTypes = {

};
export default connect((state) => {
    return {
        language: state.auth.language,
        isLoading: state.otaReducer.isLoading,
        hardwareListCollectionInProgress: state.otaReducer.hardwareListCollectionInProgress,
        hardwareList: state.otaReducer.hardwareList,
        hardwareListCollectionError: state.otaReducer.hardwareListCollectionError,
        firmwareCreated: state.otaReducer.firmwareCreated,
        firmwareUpdated: state.otaReducer.firmwareUpdated,
        firmwareInfoSubmitting: state.otaReducer.firmwareInfoSubmitting,
        errorMessageForFirmwareCreation: state.otaReducer.errorMessageForFirmwareCreation,
        errorMessageForFirmwareUpdate: state.otaReducer.errorMessageForFirmwareUpdate
    }
}, {...actions}) (CreateOrEditFirmware);
